import React, { useState, useEffect } from 'react';

import { Form, Field } from 'react-final-form';
import { Grid, Button, Typography, Divider, Snackbar } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

import { ExperiencerService } from 'core/api/services/experiencer';
import { useTranslation } from 'core/contexts/localization';
import { useExperiencers } from 'core/contexts/experiencer';
import { useAuth } from 'core/contexts/auth';

import EditIcon from 'assets/icons/edit-white.svg';

import TextInput from 'components/Input/Text';
import PhoneNumber from 'components/Input/PhoneNumber';
import TextInputDescription from './TextInput';
import {
    HeaderAvatar,
    TextFieldAdapter,
    AutocompleteAdapter,
    SaveChangesModal,
} from '../components';
import ImageCropper from '../components/ImageCropper';

import { useStyles } from './styles';
import { nationalityList } from '../mocks';

export const ExperiencerProfilePage = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const { update } = useExperiencers();
    const { userInfo: info, getUserInfoSync } = useAuth();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [userInfo, setUserInfo] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [file, setFile] = React.useState();
    const [image, setImage] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [profileChanged, setProfileChanged] = React.useState(false);
    const [modalVisible, setmodalVisible] = useState(false);
    const [userData, setUserData] = React.useState({});
    const [countryCode, setCountryCode] = React.useState('');

    const onSubmit = async (values) => {
        values = { experiencer: userInfo };
        const valuesClon = { ...values };
        delete valuesClon.experiencer.pictureURL;
        if (file)
            await ExperiencerService.updateAvatar(info?.experiencer?.id, file);
        setSaved(true);
        if (profileChanged) await update(valuesClon);
        const userInfoSync = await getUserInfoSync();
        userInfoSync.experiencer.email = userInfoSync?.email;
        setUserInfo(userInfoSync?.experiencer);
        setCountryCode(userInfoSync?.experiencer?.mobilePhoneCountryCode);
        setCanEdit(false);
        setOpenSnackBar(true);
        setUserData(userInfoSync?.experiencer);
    };

    useEffect(() => {
        getUserInfoSync().then(() => {
            if (info.experiencer) info.experiencer.email = info?.email;
            setUserData(info?.experiencer);
            setCountryCode(info?.experiencer?.mobilePhoneCountryCode);
        });
    }, []);

    useEffect(() => {
        if (info.experiencer) info.experiencer.email = info?.email;
        setUserData(info?.experiencer);
        setCountryCode(info?.experiencer?.mobilePhoneCountryCode);
    }, [info]);

    return (
        <div>
            <div className={classes.profileDiv}>
                <div
                    className={classes.leftColumn}
                    style={{ minHeight: isSmall ? 'initial' : '100vh' }}>
                    <div className={classes.leftColumnContent}>
                        <SaveChangesModal
                            profileChanged={profileChanged && canEdit}
                            saved={saved}
                            modalVisible={modalVisible}
                            setCanEdit={setCanEdit}
                        />
                        <ImageCropper
                            setFile={setFile}
                            setImage={setImage}
                            setCanEdit={setCanEdit}
                            // children={
                            //     <HeaderAvatar
                            email={info.email}
                            userName={info?.experiencer?.name}
                            picture={
                                image ||
                                userData === undefined ||
                                !userData ||
                                !userData.pictureURL
                                    ? image || '' // deberia ir una URL default por ej
                                    : `${process.env.REACT_APP_CDN_URL}${userData?.pictureURL}`
                            }
                            // onClick={() => {
                            //     setCanEdit(true);
                            // }}
                            // />
                            // }
                        />
                    </div>
                </div>
                <div className={classes.rightColumn}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            ...userData,
                        }}
                        render={({ handleSubmit, form }) => (
                            <form id="form" onSubmit={handleSubmit}>
                                <div>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                                variant="h5"
                                                className={classes.text}>
                                                {translate(
                                                    'profile.edit_profile_data'
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setCanEdit(true);
                                                }}
                                                style={{
                                                    height: 40,
                                                    margin: 'inherit',
                                                }}
                                                // className="triper-button bg-green-300"
                                                startIcon={
                                                    <img
                                                        alt=""
                                                        src={EditIcon}
                                                        width={20}
                                                    />
                                                }>
                                                {isSmall
                                                    ? null
                                                    : translate('profile.edit')}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Divider />

                                    <div>
                                        <div className={classes.sectionTitle}>
                                            <Typography
                                                variant="h6"
                                                className={classes.text}>
                                                {translate(
                                                    'profile.personal_data'
                                                )}
                                            </Typography>
                                        </div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.name = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    id="name"
                                                    name="name"
                                                    component={TextInput}
                                                    hintText={translate(
                                                        'profile.name'
                                                    )}
                                                    label={translate(
                                                        'profile.name'
                                                    )}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    name="email"
                                                    component={TextFieldAdapter}
                                                    hintText={translate(
                                                        'profile.email'
                                                    )}
                                                    label={translate(
                                                        'profile.email'
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Field
                                                    id="phone"
                                                    onFieldChange={(
                                                        value,
                                                        country
                                                    ) => {
                                                        userInfo.mobilePhone =
                                                            value;
                                                        userInfo.mobilePhoneCountryCode =
                                                            country;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    name="mobilePhone"
                                                    component={PhoneNumber}
                                                    hintText={translate(
                                                        'profile.phone'
                                                    )}
                                                    label={translate(
                                                        'profile.phone'
                                                    )}
                                                    disabled={!canEdit}
                                                    initialCountry={
                                                        countryCode || 'us'
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressCountry =
                                                            value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    startValue={
                                                        userInfo?.addressCountry
                                                    }
                                                    id="triper.country"
                                                    name="addressCountry"
                                                    component={
                                                        AutocompleteAdapter
                                                    }
                                                    options={nationalityList}
                                                    getOptionLabels={(option) =>
                                                        option.en_short_name
                                                    }
                                                    hintText={translate(
                                                        'profile.country'
                                                    )}
                                                    label={translate(
                                                        'profile.country'
                                                    )}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressCity =
                                                            value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    id="triper.city"
                                                    name="addressCity"
                                                    component={TextInput}
                                                    hintText={translate(
                                                        'profile.city'
                                                    )}
                                                    label={translate(
                                                        'profile.city'
                                                    )}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressStreet =
                                                            value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    initialValue={
                                                        info?.experiencer
                                                            ?.addressStreet
                                                    }
                                                    id="triper.address"
                                                    name="addressStreet"
                                                    component={TextInput}
                                                    hintText={translate(
                                                        'profile.address'
                                                    )}
                                                    label={translate(
                                                        'profile.address'
                                                    )}
                                                    disabled={!canEdit}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Field
                                                    style={{
                                                        marginBottom: '2em',
                                                    }}
                                                    onFieldChange={(value) => {
                                                        userInfo.description =
                                                            value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    multiline
                                                    rows={4}
                                                    id="description"
                                                    name="description"
                                                    component={
                                                        TextInputDescription
                                                    }
                                                    label={translate(
                                                        'profile.description'
                                                    )}
                                                    disabled={!canEdit}
                                                    maxCharacters={500}
                                                    initialCharacters={
                                                        userData?.description
                                                            ?.length || 0
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent="flex-end"
                                    alignContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={!canEdit}
                                            type="button"
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return form.reset(e);
                                            }}>
                                            {translate('profile.btn_cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return handleSubmit(e);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={!canEdit}
                                            type="submit">
                                            {translate('profile.btn_submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </div>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={1500}
                onClose={() => setOpenSnackBar(false)}
                message={translate('profile.update_notification')}
            />
        </div>
    );
};

ExperiencerProfilePage.displayName = 'ExperiencerProfilePage';

export default ExperiencerProfilePage;

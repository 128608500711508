import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.colors.scienceBlue,
    '&:hover': {
      backgroundColor: theme.palette.colors.scienceBlue,
    },
  },
}))(Button);

ColorButton.displayName = 'ColorButton';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: 'center',
        display: 'block',
    },
    form: {
        width: '30%',
    },
    percentage: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.3rem',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0.2rem 0',
    },
    checkbox: {
        marginLeft: '5%',
    },
}));

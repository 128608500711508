import React from 'react';
// import { Row, Col, Upload, Modal, Slider } from 'antd';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Slider,
    Button,
} from '@material-ui/core';
// import Dialog from 'core/libs/core-ui/components/Dialog'
import Compressor from 'compressorjs';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './canvasutils';
import './crop.css';

import { Avatar, Typography } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';
import EditIcon from 'assets/icons/edit.svg';

const handleCompressedUpload = (
    target,
    setImage,
    setFile,
    setModalVisibility,
    setModalImage
) => {
    // const { fileList: newFileList } = fileList;
    // console.log('HANDLE COMPRESSED UPLOAD', newFileList);
    const fileList = target?.target?.files;
    if (fileList && fileList.length) {
        const newFileListIndex = fileList.length - 1;
        const image = fileList[newFileListIndex]; //.originFileObj;
        new Compressor(image, {
            quality: 0.8,
            maxHeight: 300,
            maxWidth: 300,
            success: (compressedResult) => {
                // console.log('compressedResult', compressedResult);
                setFile(new File([compressedResult], 'image'));
                const reader = new FileReader();
                reader.readAsDataURL(compressedResult);
                reader.onload = () => {
                    setModalImage(reader.result);
                    setModalVisibility(true);
                };
            },
        });
    }
};

const ImageCropper = ({
    children,
    setImage,
    setFile,
    setCanEdit,
    picture,
    userName,
    email,
    nickname,
}) => {
    const [modalVisibility, setModalVisibility] = React.useState(false);
    const [modalImage, setModalImage] = React.useState();
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
    const [zoom, setZoom] = React.useState(1);

    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const resetProps = () => {
        setModalImage(undefined);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    const onOk = async () => {
        setModalVisibility(false);
        const croppedImage = await getCroppedImg(modalImage, croppedAreaPixels);
        setImage(croppedImage.base64);
        setFile(croppedImage.file);
        resetProps();
        setCanEdit(true);
    };

    const onClose = () => {
        setModalVisibility(false);
        setCanEdit(false);
        resetProps();
    };

    const onCropComplete = React.useCallback(
        (croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
        },
        []
    );

    return (
        <React.Fragment>
            <Button component="label">
                <input
                    id="file"
                    type="file"
                    onChange={(target) =>
                        handleCompressedUpload(
                            target,
                            setImage,
                            setFile,
                            (bool) => {
                                if (!modalVisibility) {
                                    setModalVisibility(bool);
                                }
                            },
                            setModalImage
                        )
                    }
                    hidden
                />
                {/* {children} */}
            </Button>
            <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                justifyContent="center">
                <Grid
                    container
                    position="relative"
                    direction={`${isSmall ? 'row' : 'column'}`}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center">
                    <div style={{ position: 'relative' }}>
                        <Avatar className={classes.avatar} src={picture} />
                        <Button
                            className={classes.editButton}
                            component="label">
                            <input
                                id="file"
                                type="file"
                                onChange={(target) =>
                                    handleCompressedUpload(
                                        target,
                                        setImage,
                                        setFile,
                                        (bool) => {
                                            if (!modalVisibility) {
                                                setModalVisibility(bool);
                                            }
                                        },
                                        setModalImage
                                    )
                                }
                                hidden
                            />
                            <img
                                alt="edit"
                                style={{
                                    position: 'absolute',
                                    width: '1em',
                                }}
                                src={EditIcon}
                            />
                        </Button>
                    </div>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        alignContent="space-between"
                        justifyContent="center">
                        <Typography variant="h3" className={classes.avatarName}>
                            {nickname}
                        </Typography>
                    </Grid>
                    <Typography variant="h5" className={classes.avatarEmail}>
                        {email}
                    </Typography>
                </Grid>
            </Grid>

            <Dialog
                open={modalVisibility}
                onOk={onOk}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Edit Image</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid
                            item
                            style={{
                                minWidth: isSmall ? 250 : 500,
                                height: isSmall ? 300 : 400,
                            }}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                }}>
                                <Cropper
                                    image={modalImage}
                                    crop={crop}
                                    zoom={zoom}
                                    cropShape={'round'}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid item xs={10} offset={4}>
                            <div className="crop-control">
                                <button className="crop-control-zoom-button">
                                    -
                                </button>
                                <Slider
                                    defaultValue={1}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    onChange={(event, value) => setZoom(value)}
                                    // style={{ flex: 1 }}
                                />
                                <button className="crop-control-zoom-button">
                                    +
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onOk} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ImageCropper;

import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';

const expDetailRoutes = {
    save: '/api/v1.1.0/experience/',
    update: '/api/v1.1.0/experience/',
    imageDetail: '/image',
    videoDetail: '/youtube',
};

export const ExperienceInfoMediaService = {
    imageSave: async (expId, newData) => {
        console.log(
            expId,
            '<-- exp id',
            JSON.stringify(newData),
            "<--- ON 'ImageSave' POST SERVICE",
            `${expDetailRoutes.save}${expId}${expDetailRoutes.imageDetail}`,
            '<--- EndPoint'
        );
        try {
            const formData = new FormData();
            formData.append('images', newData);
            await fetch(
                `${expDetailRoutes.save}${expId}${expDetailRoutes.imageDetail}`,
                {
                    method: Method.POST,
                    authenticated: true,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        } catch (err) {
            throw new Error(err);
        }
    },
    mediaDelete: async (expId, mediaId) => {
        console.log(
            expId,
            '<-- exp id',
            mediaId,
            "<--- ON 'imageDetail' Delete SERVICE",
            `${expDetailRoutes.save}${expId}/media/${mediaId}`,
            '<--- delete endpoint'
        );
        try {
            await fetch(`${expDetailRoutes.save}${expId}/media/${mediaId}`, {
                method: Method.DELETE,
                authenticated: true,
            });
        } catch (err) {
            throw new Error(err);
        }
    },
    imageUpdate: async (expId, newData) => {
        try {
            console.log(
                expId,
                '<-- exp id',
                newData,
                "<--- ON 'imageDetail' PATCH SERVICE"
            );
            await fetch(
                `${expDetailRoutes.save}${expId}${expDetailRoutes.imageDetail}`,
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: JSON.stringify(newData),
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        } catch (err) {
            throw new Error(err);
        }
    },
    videoSave: async (expId, newData) => {
        try {
            console.log(
                expId,
                '<-- exp id',
                JSON.stringify(newData),
                "<--- ON 'videoDetail' POST SERVICE",
                `${expDetailRoutes.save}${expId}${expDetailRoutes.videoDetail}`,
                '<--- EndPoint'
            );
            await fetch(
                `${expDetailRoutes.save}${expId}${expDetailRoutes.videoDetail}`,
                {
                    method: Method.POST,
                    authenticated: true,
                    data: JSON.stringify(newData),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
        } catch (err) {
            throw new Error(err);
        }
    },
    videoUpdate: async (expId, newData) => {
        try {
            console.log(
                expId,
                '<-- exp id',
                newData,
                "<--- ON 'videoDetail' PATCH SERVICE"
            );
            await fetch(
                `${expDetailRoutes.save}${expId}${expDetailRoutes.videoDetail}`,
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: JSON.stringify(newData),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
        } catch (err) {
            throw new Error(err);
        }
    },
};

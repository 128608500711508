import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { useBookings } from 'core/contexts/booking';

import { ExperiencerService } from 'core/api/services/experiencer';

import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import BookingOrigin from 'core/libs/types/BookingOrigin/BookingOrigin';
import { isEmpty } from 'core/packages/utils';

const GlobalBookingsTable = ({
    experiencesId,
    email,
    bookingCode,
    showPreviousBookings,
    load,
    resources,
    dateSearch,
}) => {
    const { t: translate } = useTranslation();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const history = useHistory();
    const {
        BOOKINGS_PER_PAGE,
        page,
        setPage,
        orderBy,
        setOrderBy,
        direction,
        setDirection,
    } = useBookings();

    // pagination variables
    const [bookings, setBookings] = useState([]);
    const [totalBookings, setTotalBookings] = useState(0);
    const [firstSrvCall, setFirstSrvCall] = useState(true);
    const emailDebounce = useDebounce(email, 300);
    const bookingCodeDebounce = useDebounce(bookingCode, 300);

    useEffect(() => {
        setFirstSrvCall(true);
    }, [showPreviousBookings, email, bookingCode]);

    useEffect(() => {
        if (experiencesId && experiencesId.length > 0) {
            ExperiencerService.getBookings(
                experiencesId,
                emailDebounce,
                bookingCodeDebounce,
                showPreviousBookings,
                page,
                BOOKINGS_PER_PAGE,
                orderBy,
                direction,
                resources,
                dateSearch
            ).then((res) => {
                const bookings = prettifyBookings(res.bookings);
                setBookings(bookings);
                setTotalBookings(res.totalBookings);
                setFirstSrvCall(false);
            });
        } else {
            setBookings([]);
            setTotalBookings(0);
        }
    }, [
        experiencesId,
        emailDebounce,
        bookingCodeDebounce,
        showPreviousBookings,
        page,
        load,
        orderBy,
        direction,
        resources,
        dateSearch,
    ]);

    const columns = [
        {
            align: 'left',
            val: 'codeFormatted',
            label: 'Booking Code',
            column: 'ci_code',
        },
        { val: 'triper', label: 'Triper', column: 'ci_first_name' },
        { val: 'email', label: 'Email', column: 'ci_email' },
        {
            val: 'experienceName',
            label: 'Activity Name',
            column: 'ci_experience_detail_id',
        },
        { val: 'dateFormatted', label: 'Activity Date', column: 'ci_date_utc' },
        {
            val: 'resourcesFormatted',
            label: 'Resources',
        },
        { val: 'quantity', label: 'Quantity', column: 'ci_quantity' },
        {
            val: 'paidPriceFormatted',
            label: 'Paid Ammount',
            column: 'ci_paidPrice',
        },
        { val: 'priceFormatted', label: 'Price', column: 'ci_paidPrice' },
        { align: 'center', val: 'originString', label: 'Origin Id' },
        {
            align: 'center',
            val: 'statusString',
            label: 'Status',
            column: 'ci_status',
        },
        {
            align: 'center',
            val: 'checkinString',
            label: 'Check In',
            // column: 'ci_status',
        },
        {
            align: 'center',
            val: 'additional',
            label: 'Additional',
            // column: 'ci_status',
        },
    ];
    // const resourcesRawData = [
    //     { id: 1, name: 'Pantalon blanco' },
    //     { id: 2, name: 'Salon 1' },
    // ];
    const formattedDataResources = (rawData) => {
        if (!rawData) return null;
        const names = rawData.map((item) => item.name);
        return names.join(', ');
    };

    const prettifyBookings = (bookings = []) => {
        return bookings.map((booking) => {
            return {
                triper: `${booking.firstName} ${booking.lastName}`,
                statusString: BookingStatus.getLabel(booking.status),
                originString: BookingOrigin.getLabel(booking.originId),
                dateFormatted: DateFormattedUtils(booking.dateUTC, null, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: booking.timezone || 'UTC',
                }),
                codeFormatted:
                    booking.code.length > 6
                        ? booking?.code?.slice(0, 6)
                        : booking.code,
                priceFormatted: `${booking.currency} ${MoneyFormattedUtils(
                    booking.price
                )}`,
                resourcesFormatted: !isEmpty(booking.resources)
                    ? formattedDataResources(booking.resources)
                    : '',
                paidPriceFormatted: `${booking.currency} ${MoneyFormattedUtils(
                    booking.paidPrice
                )}`,
                checkinString:
                    booking.checkIn === true
                        ? translate('checkin.completed')
                        : booking.checkIn === false
                        ? translate('checkin.pending')
                        : translate('checkin.na'),
                ...booking,
            };
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSort = (column) => {
        if (orderBy === column) {
            setDirection(direction === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setDirection('asc');
        }
    };
    const onClick = (event, id) => {
        history.push(`/booking/${id}`);
    };

    return (
        <React.Fragment>
            <BasicTable
                columns={columns}
                values={bookings}
                handleSort={handleSort}
                orderBy={orderBy}
                direction={direction}
                onClick={onClick}
            />
            <br />
            <Grid container alignItems="flex-end">
                <Grid item xs={1} sm={7}></Grid>
                <Grid item xs={11} sm={5}>
                    {totalBookings / BOOKINGS_PER_PAGE < 1 ? null : (
                        <PaginationControlled
                            count={Math.ceil(totalBookings / BOOKINGS_PER_PAGE)}
                            page={page}
                            onChange={handlePageChange}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default GlobalBookingsTable;

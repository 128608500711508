import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    saveModal: {
        padding: '14px',
        minWidth: '282px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '5px',
        },
    },
    smTitle: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '4px',
    },
    smDivider: {
        width: '100%',
        marginTop: '9px',
        borderBottom: 'solid 0.5px #4F4F4F',
        background: 'transparent',
        textAlign: 'center',
        opacity: 0.75,
    },
    smMessage: {
        width: '70%',
        textAlign: 'center',
        paddingTop: '36px',
        paddingBottom: '36px',
        margin: '0 auto',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        color: '#828282',
    },
    smFooter: {
        height: '50px',
        marginRight: '11px',
    },
    smCancelButton: {
        float: 'right',
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smSaveButton: {
        float: 'right',
        paddingTop: '9px',
        marginLeft: '14px',
        background: '#2F80ED',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smCard: {
        maxWidth: '320px',
        margin: '10px 10px',
        display: 'inline-block',
    },
    smCardMedia: {
        transition: '3s',
        height: '110px',
    },
}));

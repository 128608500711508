import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationES from 'core/locales/translations/es.json';
import translationEN from 'core/locales/translations/en.json';

import FlagES from 'assets/images/flags/es.png';
import FlagEN from 'assets/images/flags/en.png';

const resources = {
  es: {
    translation: translationES,
    image: FlagES,
  },
  en: {
    translation: translationEN,
    image: FlagEN,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      whitelist: Object.keys(resources),
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    },
    (err) =>
      err && console.error('@i18next [translations cannot be loaded]: ', err)
  );

export const config = i18n;

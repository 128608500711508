import Layout from '../../components/Layout';
import ResourcePageComponent from './componenets/ResourcePage';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

const ResourcePage = () => {
    const classes = useStyles();

    return (
        <Layout contentClassName={classes.content}>
            <Grid>
                <ResourcePageComponent />
            </Grid>
        </Layout>
    );
};

ResourcePage.displayName = 'ResourcePage';

export default ResourcePage;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: 'center',
        display: 'block',
    },
    timeSelectors: {
        margin: '0.3rem 0',
    },
}));

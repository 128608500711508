import Layout from '../../components/Layout';
import { useAuth } from 'core/contexts/auth';
// import TriperProfilePage from 'modules/Profile/Triper';
// import TriperProfilePage from 'modules/Profile/Triper/profile';
import ExperiencerProfilePage from 'modules/Profile/Experiencer';

import { useStyles } from './styles';

const ProfilePage = () => {
    const { role } = useAuth();

    const classes = useStyles();

    return (
        <Layout contentClassName={classes.content}>
            {/* {role === 'triper' && <TriperProfilePage />} */}
            {role === 'experiencer' && <ExperiencerProfilePage />}
        </Layout>
    );
};

ProfilePage.displayName = 'ProfilePage';

export default ProfilePage;

export const parseTriperToModel = (triper) => ({
  id: triper?.id,
  nickname: triper?.nickname,
  status: triper?.status,
  pictureURL: triper?.pictureURL,
  addressStreet: triper?.addressStreet,
  addressStreetAlt: triper?.addressStreetAlt,
  addressCity: triper?.addressCity,
  addressState: triper?.addressState,
  addressCountryId: triper?.addressCountryId,
  addressPC: triper?.addressPC,
  other: triper?.other,
  // officePhone: triper?.officePhone,
  birthDate: triper?.birthDate,
  mobilePhone: triper?.mobilePhone,
  // email: triper?.email,
  // emailAlt: triper?.emailAlt,
  // emergencyDetails: triper?.emergencyDetails,
  emergencyNumber: triper?.emergencyNumber,
  emergencyContact: triper?.emergencyContact,
  paymentDetails: triper?.paymentDetails,
  // billingDetails: triper?.billingDetails,
  // companySize: triper?.companySize,
  addressCountryId: triper?.addressCountryId,
  // profession: triper?.profession,
  // tc: triper?.tc,
  nationality: triper?.nationality,
  gender: triper?.gender || 'N/A',
  firstName: triper?.firstName,
  lastName: triper?.lastName,
  birthDate: triper?.birthDate,
  currency: triper?.currency,
});

export const parseTriperToObject = (data) => ({
  ...data,
});

import { useState } from 'react';
import { bool } from 'prop-types';
import { Form, Field } from 'react-final-form';
import {
    Grid,
    Link,
    Button,
    CardActions,
    CircularProgress,
    Typography,
    Card,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

import { useAuth } from 'core/contexts/auth';
import { useTranslation } from 'core/contexts/localization';
import TextInput from 'components/Input/Text';
import { useStyles } from './styles';
import { validateLogin } from 'core/packages/validate/login';
import { ROUTES } from 'core/routing/routes';

const Login = () => {
    const { login, loading } = useAuth();

    const history = useHistory();
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);

    const handleSubmit = async (values) => {
        setLoadingLogin(true);
        const successLogin = await login(values);
        setLoadingLogin(false);
        if (successLogin) {
            history.push('/home');
        }
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateLogin = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate('form.required');
        } else if (!validateEmail(values.email)) {
            errors.email = translate('form.invalid.email');
        }
        if (!values.password) {
            errors.password = translate('form.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validateLogin}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Card className={classes.card}>
                        <Typography
                            variant="h4"
                            component="h1"
                            align="center"
                            paragraph>
                            {translate('landing.login.title')}
                        </Typography>
                        <div className={classes.content}>
                            <Field
                                name="email"
                                component={TextInput}
                                label={translate(
                                    'landing.login.fields.username'
                                )}
                                disabled={loading}
                                className={classes.input}
                            />
                            <Field
                                name="password"
                                component={TextInput}
                                label={translate(
                                    'landing.login.fields.password'
                                )}
                                type={showPassword ? 'text' : 'password'}
                                disabled={loading}
                                className={classes.input}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }>
                                                {showPassword ? (
                                                    <VisibilityOutlined />
                                                ) : (
                                                    <VisibilityOffOutlined />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Grid container justifyContent="center">
                                <Link
                                    href=""
                                    style={{ color: '#1890ff' }}
                                    onClick={() =>
                                        history.push(
                                            ROUTES.FORGOTTEN_PASSWORD.SEND
                                        )
                                    }>
                                    {translate('reset_password.forgotten')}
                                </Link>
                            </Grid>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                                className={classes.submitButton}
                                onClick={handleSubmit}>
                                {loading & loadingLogin ? (
                                    <CircularProgress size={25} thickness={4} />
                                ) : (
                                    translate('landing.login.button')
                                )}
                            </Button>
                            {/*
                <GoogleLogin
                clientId="713949853621-4dvlrldml0mndq8ggp8321ogljjk3t0i.apps.googleusercontent.com"
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    className={classes.submitButton}
                    fullWidth>
                    Ingresá con Google
                  </Button>
                )}
                buttonText="Login"
                onSuccess={(response) => {}}
                onFailure={(err) => console.error(err)}
                cookiePolicy={'single_host_origin'}
              />
         */}
                        </CardActions>
                    </Card>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    loading: bool.isRequired,
};

Login.displayName = 'Login';

export default Login;

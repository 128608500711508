import { isEmpty } from 'core/packages/utils';

import { parseTripExperienceToModel } from './tripExperience';
import {
    parseTripLocationToModel,
    parseTripLocationToObject,
} from './tripLocation';

export const parseTripToModel = (trip) => ({
    id: trip?.id,
    name: trip?.name,
    from: trip?.start,
    to: trip?.end,
    experiences: !isEmpty(trip?.experiences)
        ? trip?.experiences.map(parseTripExperienceToModel)
        : [],
    locations: !isEmpty(trip?.locations)
        ? trip?.locations.map(parseTripLocationToModel)
        : [],
    status: trip?.status,
    coTripers: trip?.cotripers || [],
});

export const parseTripToObject = (trip) => ({
    ...trip,
    locations: !isEmpty(trip?.locations)
        ? trip?.locations.map(parseTripLocationToObject)
        : [],
});

export const parseObjectToTripBudget = (tripBudget) => ({
    name: tripBudget.experienceName,
    price: tripBudget.price,
    currency: tripBudget.currency.toUpperCase(),
    location: tripBudget.location,
    // el proximo campo es solo para la lista (asi posee una unique key)
    key: tripBudget.id,
    date: tripBudget.from.slice(0, tripBudget.from.lastIndexOf('T')),
    experienceDetailId: tripBudget.experienceDetailId,
    tripExperienceId: tripBudget.id,
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },

    formTitle: {
        marginBottom: theme.spacing(2),
    },
    formFormControl: {
        marginBottom: theme.spacing(3),
    },
}));

import React, { useState } from 'react';
import { useMediaQuery, Grid, Tabs, Tab } from '@material-ui/core';
import DatePicker from '../DatePicker';

function DateRangePicker({ label, value, onChange, ...props }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [tabValue, setTabValue] = useState('date');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if(tabValue !== 'range') {
        onChange({startDate: date});
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate) {
        onChange({startDate, endDate: date});
    }
  };

  return (
    <div
        style={{
            
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)', // Darker shadow
            borderRadius: '4px',
            background: 'white',
            padding: '8px',
            marginTop: '4px',
            marginLeft: '-8px',
            minWidth:!isSmall && tabValue === 'range' ? 700 : 0,
            width:'100%'
        }}
>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Date" value="date" />
        <Tab label="Range" value="range" />
      </Tabs>
      {tabValue === 'date' && (
        <DatePicker
          label={label}
          value={startDate}
          onChange={handleStartDateChange}
          {...props}
        />
      )}
      {tabValue === 'range' && (
        <>
            <Grid container direction='row'>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        {...props}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        minDate={startDate}
                        {...props}
                    />
                </Grid>
            </Grid>
        </>
      )}
    </div>
  );
}

export default DateRangePicker;

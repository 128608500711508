import React from 'react';
import {
    Modal,
    Container,
    Grid,
    Typography,
    Paper,
    Hidden,
    Button,
} from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import TermsAndConditionsLanding from 'modules/TermsAndConditions/TermsAndConditionsLanding';
import CloseIcon from '@material-ui/icons/Close';

const TermsAndConditions = ({ open, onClose }) => {
    const { t: translate } = useTranslation();

    // FIXME: modularizar en cuanto sea posible, se deberia crear un modal generico que reciba las medidas y style
    // la idea es que utilice 3 breakpoints, por eso se necesitan 3 Hidden
    return (
        <>
            <Hidden smDown>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '72vh',
                            top: '50%',
                            left: '50%',
                            width: '40em',
                            transform: 'translate(-50%,-50%)',
                        }}>
                        <Grid
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}>
                            <Grid item sm={11} md={11} lg={11} xl={11}>
                                <h2>
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </h2>
                            </Grid>
                            <Grid item sm={1} md={1} lg={1} xl={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}>
                                    <CloseIcon />
                                </button>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    height: '55vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}>
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}>
                                    {/* <Typography
                    gutterBottom
                    paragraph
                    style={{ textAlign: 'justify' }}> */}
                                    <TermsAndConditionsLanding />
                                    {/* </Typography> */}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Modal>
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '72vh',
                            top: '50%',
                            left: '50%',
                            width: '35em',
                            transform: 'translate(-50%,-50%)',
                        }}>
                        <Grid
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}>
                            <Grid item sm={11} md={11} lg={11} xl={11}>
                                <h2>
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </h2>
                            </Grid>
                            <Grid item sm={1} md={1} lg={1} xl={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}>
                                    <CloseIcon />
                                </button>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    height: '55vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}>
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}>
                                    <Typography
                                        gutterBottom
                                        paragraph
                                        style={{ textAlign: 'justify' }}>
                                        <TermsAndConditionsLanding />
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Modal>
            </Hidden>
            <Hidden smUp>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '70vh',
                            top: '50%',
                            left: '50%',
                            width: '25em',
                            transform: 'translate(-50%,-50%)',
                        }}>
                        <Grid
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}>
                            <Grid item sm={11}>
                                <p
                                    style={{
                                        fontSize: '1.4em',
                                        display: 'inline',
                                    }}>
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </p>
                            </Grid>
                            <Grid item sm={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}>
                                    <CloseIcon />
                                </button>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    height: '60vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}>
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}>
                                    <Typography
                                        gutterBottom
                                        paragraph
                                        style={{ textAlign: 'justify' }}>
                                        <TermsAndConditionsLanding />
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Modal>
            </Hidden>
        </>
    );
};

export default TermsAndConditions;

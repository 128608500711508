import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';
import { parseModusToModel } from 'core/api/parser/modus';

const ModusRoutes = {
    getAll: '/api/v1.0.0/modus/',
};

export const ModusService = {
    get: async () => {
        try {
            const { data } = await fetch(ModusRoutes.getAll, {
                method: Method.GET,
                authenticated: true,
            });
            return isEmpty(data) ? [] : data.map(parseModusToModel);
        } catch (err) {
            throw new Error(err);
        }
    },
};

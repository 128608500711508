import React, { useContext, useState, useEffect } from 'react';

import axios from 'axios';

import { useAuth } from 'core/contexts/auth';

const CurrencyContext = React.createContext(null);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('COP'); // es el valor default en pesos colombianos
  const [exchange, setExchange] = useState(0);
  const { userInfo } = useAuth();

  useEffect(() => {
    // console.log("CURRENCY", userInfo)
    setCurrency(userInfo?.triper?.currency || 'COP');
  }, [userInfo]);

  const getCurrencyExchange = async (fromCurrency, toCurrency) => {
    console.log('from ====', fromCurrency, 'to ====', toCurrency);
    const options = {
      method: 'GET',
      url: 'https://currency-exchange.p.rapidapi.com/exchange',
      params: {
        to: toCurrency.toUpperCase() || 'COP',
        from: fromCurrency.toUpperCase() || 'USD',
        q: '1.0',
      },
      headers: {
        'x-rapidapi-key': '6dd4a3edc8msh9f07d0593500b13p1af642jsnadd7939a04c9',
        'x-rapidapi-host': 'currency-exchange.p.rapidapi.com',
      },
    };
    const response = await axios.request(options);
    // TODO: es necesario el setter?
    setExchange(response.data);
    return response.data;
  };

  return (
    <CurrencyContext.Provider
      value={{
        exchange,
        currency,
        getCurrencyExchange,
        setCurrency,
      }}>
      {children}
    </CurrencyContext.Provider>
  );
};

CurrencyProvider.displayName = 'CurrencyProvider';

export const useCurrency = () => useContext(CurrencyContext);

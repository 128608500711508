import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'core/contexts/localization';
import { Grid } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';
import { useState } from 'react';
import { useStyles } from './styles';

export const Requires = ({ ...props }) => {
    const requireData = props.experienceInfoTemp.requires;

    const { t: translate } = useTranslation();
    const classes = useStyles();
    const [refreshModal, setRefreshModal] = useState(false);

    const requireModalIndex = [
        {
            requireId: translate(
                'experienceProfile.saveModal.require.requireId'
            ),
        },
    ];
    const handleInput = (input) => {
        requireData[input] = !requireData[input];
        setRefreshModal(!refreshModal);
    };

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <Grid style={{ maxWidth: 1024, margin: '20px 20px 20px 20px' }}>
            {requireModalIndex.map((entry, index) => {
                const objKey = Object.keys(entry);
                return (
                    <CardActions
                        key={`${objKey}-${index}-cardActions`}
                        className={classes.cardAction}>
                        <GreenCheckbox
                            key={`${objKey}-${index}-GreenCheckbox`}
                            id={`${objKey}-${index}-checkBox`}
                            checked={requireData[objKey]}
                            onClick={(e) =>
                                handleInput(e.target.id.split('-')[0])
                            }
                            color="#2F80ED"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <Typography
                            id={`cardMedia-${index}-cardText2`}
                            key={`${entry}-${index}-Typography`}
                            style={{ color: '#2F80ED' }}
                            variant="body2"
                            component="label">
                            {entry[objKey]}
                        </Typography>
                    </CardActions>
                );
            })}
        </Grid>
    );
};

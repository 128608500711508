import React, { useState, useEffect, useMemo, useRef } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
// import i18n from 'i18next';
import { Hidden, Grid, Select, MenuItem, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import FullCalendar from '@fullcalendar/react';
import timegrid from '@fullcalendar/timegrid';
import daygrid from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import luxon from '@fullcalendar/luxon3';

import { useTranslation } from 'core/contexts/localization';

import getFullcalendarLocale from 'core/locales/getFullcalendarLocale';

import { TIME_ZONES } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useStyles } from './styles';

const CalendarPage = ({
    useCalendarPage,
    schedules,
    renderEventContent,
    setCalendarRange,
    value,
    onChange,
    start,
    end,
    range,
    ref,
    onSelect,
    onDismiss,
    tz,
    setTz,
    title,
    isSmall,
    showCreateEventeButton = true,
    onEventClick,
    children,
    ...props
}) => {
    // const history = useHistory();
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [calendarEvents, setCalendarEvents] = useState([
        {
            title: 'No events available',
            start: new Date('1970-01-01T00:00:00'), // Use old date
            end: new Date('1970-01-01T01:00:00'),
        },
    ]);
    const [date, setDate] = useState(new Date());
    const calendarRef = useRef(null);

    useEffect(() => {
        setTz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, []);

    useEffect(() => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi.removeAllEvents()
        calendarApi.addEventSource(calendarEvents);
    },[calendarEvents]);

    useEffect(() => {
        if (schedules.length) {
            setCalendarEvents([...schedules]);
        }
    }, [schedules]);

    
    const handleChangeOfDate = (date) => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.gotoDate(date);
    };

    useEffect(() => {
        if (isSmall) {
            calendarRef?.current?.getApi().changeView('timeGridFourDay');
        } else {
            calendarRef?.current?.getApi().changeView('timeGridWeek');
        }
    }, [isSmall]);

    const Calendar = useMemo(
        () => (
            <>
                <FullCalendar // TODO: Most of this should be able to be override
                    locale={getFullcalendarLocale()}
                    datesSet={(data) => {
                        setCalendarRange({ start: data.start, end: data.end });
                        if (
                            data.start.getTime() > date.getTime() ||
                            data.end.getTime() < date.getTime()
                        ) {
                            setDate(data.start);
                        }
                    }}
                    timeZone={tz || 'local'}
                    firstDay={1}
                    firstHour="10am"
                    minTime="10am"
                    ref={calendarRef}
                    plugins={[daygrid, timegrid, interaction, luxon]}
                    initialView={isSmall ? 'timeGridFourDay' : 'timeGridWeek'}
                    allDaySlot={false}
                    views={{
                        timeGridFourDay: {
                            type: 'timeGrid',
                            duration: { days: 3 },
                            buttonText: '3 ' + translate('fullcalendar.days'),
                        },
                    }}
                    slotDuration="00:30:00"
                    slotLabelInterval={30}
                    slotMinutes={30}
                    headerToolbar={{
                        right: 'prev,next',
                        center: isSmall
                            ? 'timeGridFourDay,timeGridDay'
                            : 'dayGridMonth,timeGridWeek,timeGridFourDay,timeGridDay',
                        start: '',
                        end: isSmall ? 'prev,next' : '',
                    }}
                    slotLabelFormat={{
                        hour: 'numeric',
                        hour12: false,
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: false,
                    }}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short',
                        omitZeroMinute: true,
                    }}
                    // selectConstraint={{
                    //     start: format(new Date(), 'yyyy-MM-dd'),
                    // }}
                    events={calendarEvents}
                    eventContent={renderEventContent}
                    eventDisplay="block"
                    selectable={true}
                    select={onSelect} // This is when selecting slot in the calendar
                    eventClick={(event) => {
                        // This is when clicking an existing event in the calendar
                        onEventClick(event);
                    }}
                    // eventsSet={handleEvents}
                />
            </>
        ),
        [isSmall, calendarEvents, tz]
    );

    const datePickerRef = useRef();

    return (
        <React.Fragment>
            <Grid container className={classes.calendarContainer}>
                <Hidden smDown>
                    <Grid item md={3} className={classes.datepicker}>
                        <div className={classes.datepickerHeader}>
                            <h3 className={classes.datepickerTitle}>
                                {translate('profile.schedule')}
                            </h3>
                            <h4 style={{ fontSize: '18px' }}>{title}</h4>
                        </div>
                        <Grid item xs={12}>
                            { showCreateEventeButton ?
                            <Button
                                
                                style={{
                                    width: '90%',
                                    margin: '0.5rem 0',
                                    marginBottom: '15px',
                                    color: '#fff',
                                    backgroundColor: '#71ba6c',
                                }}
                                variant="contained"
                                onClick={onSelect}>
                                {/* TODO: Tranlsate this */}
                                CREAR
                            </Button> 
                            : <></>}
                        </Grid>
                        <Select
                            style={{ width: '90%' }}
                            variant="outlined"
                            labelId="timezone"
                            id="timezone"
                            value={tz}
                            onChange={(e) => setTz(e?.target?.value)}
                            label="Time Zone">
                            {TIME_ZONES.map((tz) => (
                                <MenuItem
                                    style={{ color: 'black' }}
                                    value={tz.id}>
                                    {tz.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <DatePicker
                            ref={datePickerRef}
                            autoOk
                            variant="static"
                            value={date}
                            onChange={(date) => {
                                setDate(date);
                                handleChangeOfDate(date);
                            }}
                            disableToolbar
                        />
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="center">
                        <Select
                            style={{ width: '90%' }}
                            variant="outlined"
                            labelId="timezone"
                            id="timezone"
                            value={tz}
                            onChange={(e) => setTz(e?.target?.value)}
                            label="Time Zone">
                            {TIME_ZONES.map((tz) => (
                                <MenuItem
                                    style={{ color: 'black' }}
                                    value={tz.id}>
                                    {tz.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        { showCreateEventeButton ?
                            <Button
                                style={{
                                    width: '100%',
                                    margin: '0.5rem 0',
                                    color: '#fff',
                                    backgroundColor: '#71ba6c',
                                }}
                                variant="contained"
                                onClick={onSelect}>
                                {/* TODO: Tranlsate this */}
                                CREAR
                            </Button>
                        : <></>}
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={12} md={9}>
                    <div className={classes.calendar}>{Calendar}</div>
                </Grid>
            </Grid>
            {children}
        </React.Fragment>
    );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;

import React from 'react';
import PhoneInput from 'react-phone-input-2';

const PhoneComponent = ({ country, value, label }) => {
  return (
    <React.Fragment>
      <style type="text/css">
        {`.react-tel-input .form-control {
                    color: rgba(0, 0, 0, 0.38);
                    }

                .react-tel-input .form-control:hover {
                    border-color: rgba(0, 0, 0, 0.38); 
                    cursor: default;
                    }
                
                .react-tel-input .selected-flag .arrow {
                    border-top: 4px solid rgba(0, 0, 0, 0.38);
                    }`}
      </style>
      <PhoneInput
        disabled
        country={country || 'us'}
        value={value || '123456789'}
        placeholder=""
        specialLabel={label}
      />
    </React.Fragment>
  );
};

export default PhoneComponent;

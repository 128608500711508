import { useStyles } from './styles';

const GenericBox = (props) => {
    const classes = useStyles();

    const { title, subtitle } = props;

    return (
        <div className={classes.box}>
            <div className={classes.titleSubtitleBox}>
                <h1 style={{ fontWeight: 'bold' }}>{title}</h1>
                <h2>{subtitle}</h2>
            </div>
            <div className={classes.children}>{props.children}</div>
        </div>
    );
};

export default GenericBox;

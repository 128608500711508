import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    container: {
        padding: '60px 40px',
        [theme.breakpoints.down('xs')]: {
            padding: '60px 10px',
        },
    },
    containerTable: {
        width: '100%',
    },
    header: {
        alignItems: 'center',
    },
    title: {
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(
                2
            )}px 0px ${theme.spacing(2)}px`,
        },
    },
    selectContainer: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.down('xs')]: {
            padding: `0px ${theme.spacing(2)}px`,
        },
    },
    experiencesTitle: {
        margin: `${theme.spacing(2)}px 0px`,
        [theme.breakpoints.down('xs')]: {
            padding: `0px ${theme.spacing(2)}px`,
        },
    },
    globalBookingsTitle: {
        width: '100%',
        padding: '3rem 2rem',
    },
    globalBookingsFilter: {
        margin: '0 2rem',
        display: 'inline',
        marginBottom: '1rem',
        width: '100%',
    },
    globalBookingsSearchBoxContainer: {
        display: 'inline',
        [theme.breakpoints.down('xs')]: {
            margin: '0 2rem',
            display: 'flex',
            justifyContent: 'space-between',
            width: '20rem',
        },
    },
    globalBookingsSearchBox: {
        [theme.breakpoints.down('xs')]: {
            margin: '0.5rem 0 0 0',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: theme.spacing(2),
        outline: 'none',
        borderRadius: '4px',
    },
    input: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '1rem',
        color: '#000',
    },
}));

import { parseISO, format } from 'date-fns';
import i18next from 'i18next';

const formatter = new Intl.NumberFormat(i18next.language, {
  minimumFractionDigits: 2,
  // style: 'currency',
  // currency: 'TRY',
});

export const formatMoney = (amount) => {
  // console.log('i18next.language', i18next.language);
  return new Intl.NumberFormat(i18next.language, {
    minimumFractionDigits: 0,
    // style: 'currency',
    // currency: 'TRY',
  }).format(amount);
};

export const formatDate = (date) =>
  !!date ? format(parseISO(date), 'dd/MM/yyyy') : '';

export const formatHour = (date) =>
  !!date ? format(parseISO(date), 'HH:mm') : '';

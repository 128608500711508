import React, { useContext, useState } from 'react';

import { TriperService } from 'core/api/services/triper';
import { useAuth } from 'core/contexts/auth';

const TripersContext = React.createContext(null);

export const TripersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const { getUserInfo } = useAuth();

  const update = async (values) => {
    try {
      setLoading(true);

      const result = await TriperService.update(values.triper);

      if (!result) {
        throw new Error();
      }

      getUserInfo();

      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <TripersContext.Provider
      value={{
        update,
        loading,
      }}>
      {children}
    </TripersContext.Provider>
  );
};

TripersProvider.displayName = 'TripersProvider';

export const useTripers = () => useContext(TripersContext);

import clsx from 'clsx';
import { Avatar, ButtonBase, Typography } from '@material-ui/core';

import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';

const EllipsisButton = ({
  title,
  avatarClassName,
  iconClassName,
  icon,
  onClick = () => {},
}) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  return (
    <ButtonBase className={classes.container} onClick={onClick}>
      <Avatar className={clsx(classes.avatar, avatarClassName)}>
        {icon && (
          <img
            alt={title}
            className={clsx('MuiAvatar-fallback', iconClassName)}
            src={icon}
          />
        )}
      </Avatar>
      <Typography variant="h6" className={classes.text}>
        {translate(title)}
      </Typography>
    </ButtonBase>
  );
};

EllipsisButton.displayName = 'EllipsisButton';

export default EllipsisButton;

import axios from 'axios';
import { getSession, removeSession } from 'core/api/session';
import { useHistory } from 'react-router-dom';
import LandingPage from 'modules/Landing';
import RedirectRoute from 'core/routing/redirectRoute';
import { ROUTES } from 'core/routing/routes';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';
import { config } from 'core/locales';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

export const Method = {
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
};

/* const translateErrorMessage = (code) => {
  const { t: translate } = useTranslation();
  return translate(`errors.${code}`);
} */

// TODO: implement refresh token interceptor
export const fetch = async (url, config) => {
  const { authenticated = false, ...options } = config;

  const request = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
    },
    url,
  };
  try {
    if (authenticated) {
      const authData = getSession();
      request.headers.Authorization = `Bearer ${authData.token}`;
    }

    const response = await httpClient.request(request);

    if (response?.data?.errors && response?.data?.errors?.length) {
      console.log('Errors:', response.data.errors);
      response.data.errors.forEach((error) => {
        let { msg, code } = error;
        toast.error(/* translateErrorMessage */ `${msg} (Error code ${code})`, {
          position: 'top-right',
          theme: 'colored',
        });
      });
    }

    return response.data;
  } catch (error) {
    // 403 error here, clean session and redirect
    /* This is kind of brute force but it works.. the better way to implement this is to have a refresh token and a request interceptor for axios on the FrontEnd 
    forces window to reload, and as all auth info is erased, will prompt you to Login again thanks to RedirectRoute wrapper on the Routing index
    */
    if (error?.response?.status === 403) {
      console.log('Gotten Status 403...');
      removeSession();
      console.log('Removed session token...');
      document.location.reload();
    }
    throw error;
  }
};

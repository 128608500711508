import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(40),
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(26),
    height: theme.spacing(26),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      borderRadius: theme.spacing(),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: theme.spacing(1),
    },
  },
  text: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}));

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'core/contexts/localization';

import { Modal } from '@material-ui/core';

export function SaveChangesModal(props) {
    const { onCancel, title, saved, profileChanged, setCanEdit } = props;

    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [onOK, setOnOk] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const { t: translate } = useTranslation();
    const okText = translate('trip.create.buttons.leave');
    const cancelText = translate('trip.create.buttons.cancel');

    useEffect(() => {
        if (profileChanged === true && saved === false) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return false;
            });
        } else {
            history.block(() => {});
        }
        return () => {
            history.block(() => {});
        };
    }, [history, profileChanged, saved]);

    const handleOK = () => {
        setOnOk(true);
        history.block((prompt) => {
            setCurrentPath(prompt.pathname);
            return true;
        });
        setShowPrompt(false);
    };

    useEffect(() => {
        if (onOK && showPrompt === false) {
            history.push(currentPath);
        }
    }, [onOK, showPrompt, currentPath]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
                setCanEdit(false);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Modal
            title={title}
            visible={showPrompt}
            onOk={handleOK}
            okText={okText}
            onCancel={handleCancel}
            cancelText={cancelText}
            closable={true}>
            <span>{translate('profile.unsaved_changes')}</span>
        </Modal>
    ) : null;
}

import { parseTriperToModel } from './triper';
import { parseExperiencerToModel } from './experiencer';

export const parseLoginToModel = (data) => ({
  ...data,
});

export const parseLoginToObject = (data) => ({
  ...data,
});

export const parseRegisterToModel = (data) => ({
  ...data,
});

export const parseRegisterToObject = (user) => ({
  // nickname: user.nickname,
  email: user.email,
  loginCreds: user.password,
});

export const parseUserToModel = (user) => ({
  id: user?.id,
  email: user?.email,
  language: user?.language,
  triper: user?.triper ? parseTriperToModel(user?.triper) : {},
  experiencer: user?.experiencer
    ? parseExperiencerToModel(user?.experiencer)
    : {},
});

import React from 'react';
import {
  UsersSection,
  TourOperatorsSection,
  PersonalDataAuthorizationSection,
  PrivacyPolicyAndDataProtectionSection,
} from './ContractSections';

const TermsAndConditionsSignUp = () => {
  return (
    <React.Fragment>
      <UsersSection style={{ textAlign: 'justify' }} />
      <TourOperatorsSection style={{ textAlign: 'justify' }} />
      <PersonalDataAuthorizationSection style={{ textAlign: 'justify' }} />
      <PrivacyPolicyAndDataProtectionSection style={{ textAlign: 'justify' }} />
    </React.Fragment>
  );
};

export default TermsAndConditionsSignUp;

import { setIn } from 'final-form';

import { loginSchema } from './login';
import { signUpSchema } from './signUp';
import { tripSchema } from './trip';

const validateFormValues = (schema) => async (values) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    console.info('err: ', err);
    const errors = err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});

    return errors;
  }
};

export const validateLogin = validateFormValues(loginSchema);

export const validateSignUp = validateFormValues(signUpSchema);

export const validateTrip = validateFormValues(tripSchema);

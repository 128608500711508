// import { isEmpty } from 'core/packages/utils';

export const parseScheduleToModel = (scheduleExperience) => ({
    id: scheduleExperience?.id,
    date: scheduleExperience.date,
    start: scheduleExperience?.start,
    end: scheduleExperience.end,
    scheduleDate: scheduleExperience?.date,
    capacity: scheduleExperience?.capacity,
    currency: scheduleExperience.currency,
    price: scheduleExperience.price,
    repeat: scheduleExperience.repeat,
    expDetail: scheduleExperience.expDetail, // no existe
    expDetailId: scheduleExperience.experienceDetailId,
    daysOfWeek: scheduleExperience.daysOfWeek,
    duration: scheduleExperience.duration,
    endTime: scheduleExperience.endTime,
    startTime: scheduleExperience.startTime,
    frequency: scheduleExperience.frequency,
    unlimitedCapacity: scheduleExperience.unlimitedCapacity,
    unlimitedDuration: scheduleExperience.unlimitedDuration,
    unlimitedFrequency: scheduleExperience.unlimitedFrequency,
    slots: scheduleExperience.slots,
    privateSlot: scheduleExperience.privateSlot,
    timeZone: scheduleExperience.timeZone,
});

export const parseScheduleToObject = (data) => {
    const days = (data.daysDuration ?? 0) * 1440;
    const hours = (data.hoursDuration ?? 0) * 60;
    const minutes = data.minutesDuration ?? 0;
    const duration = days + hours + minutes;
    return {
        start: data.start,
        end: data.end,
        capacity: data.unlimitedCapacity ? null : data.capacity,
        price: data.price,
        currency: data.currency,
        repeat: data.repeat,
        unlimitedCapacity: data.unlimitedCapacity,
        unlimitedDuration: data.unlimitedDuration,
        unlimitedFrequency: data.unlimitedFrecuency,
        frequency: data.unlimitedFrecuency ? null : data.frecuencyInMinutes,
        duration: data.unlimitedDuration ? null : duration,
        privateSlot: data.privateSlot,
        timeZone: data.timeZone,
    };
};

export const parseScheduleMonthToModel = (response) => ({
    ...response,
});

import { makeStyles } from '@material-ui/core';
import bgExperiencer from '../../../assets/images/home-experiencer.jpg';
import palette from 'core/libs/core-ui/theme/colors-palette';
export const useStyles = makeStyles((theme) => ({
    container: {
        padding: `80px 60px 80px 100px`,
        backgroundColor: theme.palette.colors.white,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(4)}px ${theme.spacing(
                4
            )}px ${theme.spacing(6)}px`,
        },
    },
    gridContainer: {
        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(
        rgba(255, 255, 255, 0.70), 
        rgba(255, 255, 255, 0.75)
      ), url(${bgExperiencer}) no-repeat center center local`,
        },
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(14)}px ${theme.spacing(
                4
            )}px ${theme.spacing(6)}px`,
        },
    },
    title: {
        height: theme.spacing(4),
    },
    profile: {
        backgroundColor: 'rgb(0 68 142 / 18%)',
        color: 'rgb(0,68,142)',
    },
    newExperienceButton: {
        marginLeft: '10px',
        float: 'right',
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.green,
        '&:hover': {
            backgroundColor: palette.utriper.darkgreen,
        },
    },
    newBookingButton: {
        float: 'right',
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.blue,
        '&:hover': {
            backgroundColor: palette.utriper.darkblue,
        },
    },
}));

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import getPhoneNumberLocale from 'core/locales/getPhoneNumberLocale';

const PhoneComponent = ({ country, value, label, onFieldChange }) => {
  return (
    <React.Fragment>
      <style type="text/css">
        {`.react-tel-input .special-label {
                        color: black;
                    }`}
      </style>
      <PhoneInput
        country={country || 'us'}
        value={value}
        placeholder=""
        onChange={(value, country) => {
          console.log('Change == ', value, country);
          onFieldChange(`+${value}`, country.countryCode);
        }}
        localization={getPhoneNumberLocale()}
        specialLabel={label}
      />
    </React.Fragment>
  );
};

export default PhoneComponent;

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { TripService, TripMethods } from '../api/services/trip';

const TripsContext = React.createContext(null);
const TripContext = React.createContext(null);

const useLoadingWrapper = () => {
  const [loading, setLoading] = useState(false);

  const wrap = useCallback(
    (f) => {
      return async (...args) => {
        try {
          setLoading(true);
          return await f(...args);
        } catch (err) {
          console.error(err);
          return false;
        } finally {
          setLoading(false);
        }
      };
    },
    [setLoading]
  );

  return [loading, wrap];
};

const TripProvider = ({ children, initialSelection }) => {
  const [loading, wrap] = useLoadingWrapper();
  const [trip, setTrip] = useState(null);
  const selectedTrip = useRef(initialSelection ?? null);

  const getTrip = useMemo(
    () =>
      wrap(async () => {
        if (!selectedTrip.current) return false;

        const tripData = await TripMethods.getTrip(selectedTrip.current);
        setTrip(tripData);
        return tripData;
      }),
    [wrap]
  );

  const wrapped = useMemo(() => {
    return Object.fromEntries(
      Object.entries(TripMethods).map(([name, method]) => {
        const wrappedMethod = wrap(async (...args) => {
          if (!selectedTrip.current) return false;

          const result = await method(selectedTrip.current, ...args);
          await getTrip();
          return result;
        });
        return [name, wrappedMethod];
      })
    );
  }, [getTrip, wrap]);

  const setSelectedTrip = useCallback(
    (id) => {
      if (typeof id !== 'string' && typeof id !== 'number') {
        id = id.id;
      }

      if (id !== selectedTrip.current) {
        selectedTrip.current = id;

        return getTrip();
      }
    },
    [getTrip]
  );

  const addExperience = async (tripId, experience, selectedDate) => {
    await TripMethods.addExperienceUpdated(
      tripId,
      experience.expDetailId,
      selectedDate
    );
  };

  const changeTripStatus = async (tripId, status, tripExperienceId) => {
    await TripMethods.changeTripStatus(tripId, status, tripExperienceId);
    await getTripById(tripId);
  };

  const getTripById = async (tripId) => {
    const trip = await TripMethods.getTrip(tripId);
    setTrip(trip);
    return trip;
  };

  const addCotripers = async (tripId, cotripersEmails) => {
    if (cotripersEmails && cotripersEmails.length > 0) {
      await TripMethods.addCotriper(tripId, cotripersEmails);
    }
  };

  const updateCotripers = async (tripdId, invitedCotripers) => {
    if (invitedCotripers && invitedCotripers.length > 0) {
      await TripMethods.updateCotriper(tripdId, invitedCotripers);
    }
  };

  const deleteCotriper = async (tripId, cotriperId) => {
    await TripMethods.deleteCotriper(tripId, cotriperId);
  };

  const addLocations = async (tripId, locations) => {
    if (locations && locations.length > 0) {
      await TripMethods.addLocation(tripId, locations);
    }
  };

  const updateLocations = async (tripId, locations) => {
    if (locations && locations.length > 0) {
      await TripMethods.updateLocation(tripId, locations);
    }
  };

  const deleteLocation = async (tripId, locationId) => {
    await TripMethods.deleteLocation(tripId, locationId);
  };

  return (
    <TripContext.Provider
      value={{
        ...wrapped,
        trip,
        selectedTrip: () => selectedTrip.current,
        setSelectedTrip,
        getTrip,
        loading,
        addExperience,
        changeTripStatus,
        getTripById,
        addCotripers,
        updateCotripers,
        deleteCotriper,
        addLocations,
        updateLocations,
        deleteLocation,
      }}>
      {children}
    </TripContext.Provider>
  );
};

export const TripsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [newTripId, setNewTripId] = useState('');

  const getTrips = useCallback(async () => {
    try {
      setLoading(true);
      const tripsData = await TripService.getAll();

      if (!tripsData) {
        throw new Error();
      }

      setTrips(tripsData);
      return tripsData;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const create = async (data) => {
    try {
      setLoading(true);
      const tripsData = await TripService.create(data);
      if (!tripsData) {
        throw new Error();
      }
      console.log('NEW TRIP ID====', tripsData[0]?.id);
      setNewTripId(tripsData[0]?.id);

      await getTrips();

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const update = async (data) => {
    try {
      setLoading(true);
      const tripsData = await TripService.update(data);

      if (!tripsData) {
        throw new Error();
      }
      await getTrips();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const remove = async (data) => {
    try {
      setLoading(true);
      const tripsData = await TripService.remove(data);

      if (!tripsData) {
        throw new Error();
      }
      await getTrips();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <TripsContext.Provider
      value={{
        trips,
        loading,
        newTripId,
        setNewTripId,
        getTrips,
        create,
        update,
        remove,
      }}>
      <TripProvider>{children}</TripProvider>
    </TripsContext.Provider>
  );
};

TripsProvider.displayName = 'TripsProvider';

export const useTrip = () => {
  return useContext(TripContext);
};

export const useTrips = () => {
  const { getTrips, trips, create, newTripId, remove } =
    useContext(TripsContext);
  const { setSelectedTrip, selectedTrip, trip } = useTrip();

  // FIXME: cada vez que se use a este hook, trae a todos los trips
  useEffect(() => {
    console.log('inside');
    getTrips();
  }, []);

  return {
    setSelectedTrip,
    selectedTrip,
    getTrips,
    trips,
    create,
    newTripId,
    remove,
    trip,
  };
};

import { Grid, Typography } from '@material-ui/core';

const ExperienceData = ({
    id,
    icon,
    label,
    modalHandlers,
    location,
    disabled = false,
}) => {
    const iconText = (labelToCheck) => {
        if (id === '1') {
            if (!(location === undefined || location === '')) {
                return location;
            } else {
                return labelToCheck;
            }
        } else {
            return labelToCheck;
        }
    };

    return (
        <Grid
            item
            xs={6}
            sm={1}
            xl={1}
            key={id}
            spacing={10}
            style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                padding: 16,
                margin: '0 auto',
            }}>
            <img
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    if (!(id === '0')) {
                        modalHandlers.setContentId(id);
                        modalHandlers.setAnchorEl(true);
                    }
                }}
                alt="label"
                style={{
                    maxWidth: 50,
                    maxHeight: 50,
                    marginBottom: '24px',
                    marginTop: '24px',
                }}
                src={icon}
            />
            <Typography style={{ color: '#7A7585' }} align="center">
                {iconText(label)}
            </Typography>
        </Grid>
    );
};

ExperienceData.displayName = 'ExperienceData';

export default ExperienceData;

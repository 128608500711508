import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, TextField } from '@material-ui/core';

export const AutocompleteAdapter = (props) => {
  const {
    input,
    label,
    id,
    name,
    options,
    className,
    getOptionLabels,
    disabled,
    onFieldChange,
    startValue,
  } = props;

  const [valueState, setValueState] = React.useState(startValue);
  const [initialObjectOption, setInitialObjectOption] = React.useState({});

  React.useEffect(() => {
    // console.log('INPUT VALUE====', input.value);
    setValueState(input.value || ' ');
    const objectOption = options.find(
      (option) => option.en_short_name === input.value
    );
    setInitialObjectOption(objectOption);
  }, [input.value]);

  // console.log('PROPS===', props, valueState, initialObjectOption);

  return (
    <FormControl>
      <Autocomplete
        key={valueState || ' '}
        options={options}
        defaultValue={valueState ? initialObjectOption : ' '}
        getOptionLabel={valueState ? getOptionLabels : () => ' '}
        onInputChange={(...args) => {
          if (onFieldChange) onFieldChange(args[1]);
          // setValueState(args[1]);
          input.onChange(args[1]);
        }}
        id={id}
        name={name}
        className={className}
        disabled={disabled || false}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            defaultValue={valueState || ' '}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};

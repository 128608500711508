// import { isEmpty } from 'core/packages/utils';

import { parseLocationToModel } from './location';
import { parseModusToModel } from './modus';

export const parseExperienceToModel = (experience) => {
    return {
        ...experience,
        id: experience?.id,
        experiencerId: experience?.experiencerId,
        name: experience?.name,
        status: experience?.status,
        experienceDetailId: experience?.expDetail[0].id,
        expDetailId: experience.expDetail ? experience?.expDetail[0]?.id : null,
        // availability: experience?.availability,
    };
};

// no se si es necesario otro parse, se podria ampliar el de arriba me parece, tendria que ver mejor la API
export const parseExperienceElementListToModel = (experience) => {
    return {
        id: experience.experienceId,
        expDetailId: experience.expDetailId,
        experienceDetailName: experience.experienceDetailName,
        from: experience.start,
        to: experience.end,
        price: experience.price,
        currency: experience.currency,
        availability: experience.availability,
        name: experience.experienceName,
        duration: experience.duration,

        // este es un ejemplo de respuesta!
        // "id": 18,
        //         "experienceDetailId": 1,
        //         "createdOn": "2021-09-28T18:35:17.000Z",
        //         "updatedOn": null,
        //         "date": 3,
        //         "start": "09:00:00",
        //         "end": "12:00:00",
        //         "price": 200,
        //         "currency": "CNH",
        //         "capacity": 23,
        //         "experienceId": 28,
        //         "experienceName": "abcde",
        //         "expDetailId": 18,
        //         "experienceDetailName": "asdf",
        //         "duration": 12,
        //         "availability": 23
    };
};

export const parseExperienceToObject = (data) => ({
    ...data,
});

export const parseQrDataToModel = (qrData) => {
    return {
        // establishmentData: parseEstablishmentToModel(qrData),
        experiences: qrData.experiences.map(parseExperienceToModel),
    };
};

export const parseExperienceScheduleToModel = (experienceSchedule) => ({
    extendedProps: { ...experienceSchedule},
    id: experienceSchedule.id,
    start: new Date(experienceSchedule.startUTC).toISOString(),
    end: new Date(experienceSchedule.endUTC).toISOString(),
  });
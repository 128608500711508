import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';
import {
    parseTripToModel,
    parseTripToObject,
    parseObjectToTripBudget,
} from 'core/api/parser/trip';

const TripRoutes = {
    allTrips: '/api/v1.1.0/trip',
    trip: (id) => `/api/v1.1.0/trip/${id}`,
    addExperience: (tripId) => `/api/v1.1.0/trip/${tripId}/tripexperience`,
    location: (tripId) => `/api/v1.1.0/trip/${tripId}/location`,
    cotriper: (tripId) => `/api/v1.1.0/trip/${tripId}/cotriper`,
    tripExperience: (tripId) => `/api/v1.1.0/trip/${tripId}/tripexperience`,
    budget: (tripId) => `/api/v1.1.0/trip/${tripId}/budget`,
};

export const Status = {
    MAYBE: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    PAID: 3,
};

export const TripMethods = {
    getTrip: async (id) => {
        try {
            const { data } = await fetch(TripRoutes.trip(id), {
                method: Method.GET,
                authenticated: true,
            });

            return isEmpty(data) ? {} : data[0];
        } catch (err) {
            throw new Error(err);
        }
    },

    addExperience: async (info) => {
        try {
            const { data } = await fetch(TripRoutes.addExperience, {
                method: Method.POST,
                authenticated: true,
                data: info,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    addExperiences: (selectedTrip, experiences) => {
        Promise.all(
            experiences.map((experience) =>
                TripService.addExperience({
                    ...experience,
                    tripId: selectedTrip,
                })
            )
        );
    },

    addExperienceUpdated: async (tripId, experienceDetailId, selectedDate) => {
        // console.log("TRIPID=", tripId, " expdetid=", experienceDetailId, " selectedDate=", selectedDate);
        try {
            const { data } = await fetch(TripRoutes.tripExperience(tripId), {
                method: Method.POST,
                authenticated: true,
                data: {
                    experienceDetailId,
                    tripId,
                    from: selectedDate,
                },
            });

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    addLocation: async (selectedTrip, locations) => {
        try {
            const { data } = await fetch(TripRoutes.location(selectedTrip), {
                method: Method.POST,
                authenticated: true,
                data: {
                    locations: locations.map((location) => ({
                        location: location.location,
                        start: location.start,
                        end: location.end,
                        latitude: location.latitude,
                        longitude: location.longitude,
                    })),
                },
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateLocation: async (selectedTrip, locations) => {
        // if (!location?.id) {
        //   return TripMethods.addLocation(selectedTrip, [location]);
        // }
        try {
            const { data } = await fetch(TripRoutes.location(selectedTrip), {
                method: Method.PATCH,
                authenticated: true,
                data: {
                    locations: locations.map((location) => ({
                        id: location.id,
                        start: location.start,
                        end: location.end,
                        location: location.location,
                        latitude: location.latitude,
                        longitude: location.longitude,
                    })),
                },
            });

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteLocation: async (selectedTrip, locationId) => {
        try {
            const { data } = await fetch(
                `${TripRoutes.location(selectedTrip)}/${locationId}`,
                {
                    method: Method.DELETE,
                    authenticated: true,
                }
            );

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    addCotriper: async (selectedTrip, cotripers) => {
        try {
            const { data } = await fetch(TripRoutes.cotriper(selectedTrip), {
                method: Method.POST,
                authenticated: true,
                data: {
                    cotriper: cotripers.map((cotriper) => cotriper.name),
                },
            });

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateCotriper: async (selectedTrip, invitedCotripers) => {
        try {
            const { data } = await fetch(TripRoutes.cotriper(selectedTrip), {
                method: Method.PATCH,
                authenticated: true,
                data: invitedCotripers.map((cotriper) => ({
                    inviteId: cotriper.inviteId,
                    name: cotriper.name,
                })),
            });

            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteCotriper: async (selectedTrip, cotriperId) => {
        try {
            const { data } = await fetch(
                `${TripRoutes.cotriper(selectedTrip)}/${cotriperId}`,
                {
                    method: Method.DELETE,
                    authenticated: true,
                }
            );
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    changeTripStatus: async (tripId, status, tripExperienceId) => {
        try {
            if (!tripId) {
                throw new Error('Trip id was not provided!');
            }
            if (!status || status < 0 || status > Status.PAID) {
                throw new Error(
                    'Trip status must be between 0 and ' + Status.PAID
                );
            }
            if (!tripExperienceId) {
                throw new Error('Trip experience id was not provided!');
            }
            const { data } = await fetch(TripRoutes.tripExperience(tripId), {
                method: Method.PATCH,
                authenticated: true,
                data: {
                    tripExperienceId,
                    status,
                },
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },
};

export const TripService = {
    getAll: async () => {
        try {
            const { data } = await fetch(TripRoutes.allTrips, {
                method: Method.GET,
                authenticated: true,
            });

            return isEmpty(data) ? [] : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    create: async (info) => {
        try {
            const { data } = await fetch(TripRoutes.allTrips, {
                method: Method.POST,
                authenticated: true,
                data: parseTripToObject(info),
            });
            return isEmpty(data) ? [] : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(TripRoutes.allTrips, {
                method: Method.PATCH,
                authenticated: true,
                data: parseTripToObject(info),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },

    remove: async (info) => {
        try {
            const { data } = await fetch(TripRoutes.allTrips, {
                method: Method.PATCH,
                authenticated: true,
                data: parseTripToObject({ ...info, status: 4 }),
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },
};

export const TripBudget = {
    get: async (tripId, trip) => {
        try {
            let { data } = await fetch(TripRoutes.budget(tripId), {
                method: Method.GET,
                authenticated: true,
            });

            // filter only accepted experiences
            // TODO: comment if wanted to show ALL experiences
            data = data.filter((budget) => budget.status === Status.ACCEPTED);

            return isEmpty(data)
                ? {}
                : data.map((tripBudget) => parseObjectToTripBudget(tripBudget));

            // const filteredExperiences = [];

            // trip.locations.forEach((location) => {
            //   location.experiences.forEach((experience) => {
            //     filteredExperiences.push({
            //       id: experience.experienceDetail.experienceId,
            //       location: location.location,
            //       from: experience.from.slice(0, experience.from.lastIndexOf('T')),
            //     });
            //   });
            // });

            // return filteredExperiences;
        } catch (err) {
            throw new Error(err);
        }
    },
};

import React from 'react';
import {
    IconButton,
    Container,
    Grid,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';

const ResourceExperienceCard = ({resource, onUpdate = () => {}, onDelete = () => {}, ...props}) => {
    return (
        <Container>
            <Paper
                style={{
                    padding: 16,
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} container justifyContent='flex-end'>
                        <IconButton aria-label="delete" onClick={() => onDelete(resource.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container>
                            <Grid item xs={12} container direction='row' justifyContent='space-between' alignItems='center'>
                                <Grid item>
                                    <Typography variant="h5">{resource?.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    {resource?.resourceCategoryName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            spacing={2}>
                            <Grid item style={{ marginTop: '0.5rem' }} xs={12}>
                                <Typography variant="body1">
                                {resource?.email}
                                </Typography>
                                <Typography variant="body1">
                                {resource?.mobilePhone}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid
                            container
                            spacing={1}
                            style={{ flexDirection: 'column' }}
                            display="flex">
                            <Grid item xs={12} sm={6}>
                                <CheckboxComponent labelComponent={'Email Notification'} checked={resource?.notificationEmail || false} onChange={(value) => onUpdate({...resource, notificationEmail: value})} disabled={!resource?.email}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CheckboxComponent labelComponent={'Whatsapp Notification'} checked={resource?.notificationWhatsapp || false} disabled/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default ResourceExperienceCard;

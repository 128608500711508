import React, { useContext, useState } from 'react';
import { ExperiencerService } from 'core/api/services/experiencer';
import { useAuth } from 'core/contexts/auth';

const ExperiencersContext = React.createContext(null);

export const ExperiencersProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const { userInfo, getUserInfo } = useAuth();
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [total, setTotal] = useState(0);

    const become = async () => {
        try {
            setLoading(true);

            const result = await ExperiencerService.become({
                email: userInfo.email,
                name: userInfo?.triper?.nickname,
            });
            location.reload();
            if (!result) {
                throw new Error();
            }

            getUserInfo();

            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const update = async (values) => {
        try {
            setLoading(true);
            const result = await ExperiencerService.update(values.experiencer);
            console.log('SENT===', values.experiencer, 'RECEIVED===', result);
            if (!result) {
                throw new Error();
            }

            getUserInfo();

            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContracts = async () => {
        try {
            setLoading(true);
            const { contracts, total } =
                await ExperiencerService.getContracts();

            if (!contracts) {
                throw new Error();
            }
            // Home contracts expects you to set the state
            setContracts(contracts);
            setTotal(total || 0);

            return contracts;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <ExperiencersContext.Provider
            value={{
                become,
                update,
                loading,
                contract,
                contracts,
                setContract,
                getContracts,
            }}>
            {children}
        </ExperiencersContext.Provider>
    );
};

ExperiencersProvider.displayName = 'ExperiencersProvider';

export const useExperiencers = () => useContext(ExperiencersContext);

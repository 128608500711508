import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        border: `none`,
        // [theme.breakpoints.down('sm')]: {
        //   width: theme.spacing(10),
        //   height: theme.spacing(10),
        // },
    },
    buttonIcon: {
        marginTop: theme.spacing(1),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    editButton: {
        boxshadow: '0px 4px 14px rgba(0, 0, 0, 0.25) !important',
        position: 'absolute !important',
        height: '30px !important',
        right: '0 !important',
        minWidth: 'unset !important',
        width: '30px !important',
        borderRadius: '50% !important',
        padding: '0 !important',
        bottom: '0 !important',
        background: '#fff !important',
        '&:span': {
            fontSize: '18px !important',
        },
    },
    avatarName: {
        margin: '25px 0 5px !important',
        fontSize: '16px !important',
        textAlign: 'center',
        color: '#4f4f4f !important',
        fontWeight: 'bold !important',
    },
    avatarEmail: {
        fontSize: '16px !important',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#afb0b9 !important',
        textTransform: 'lowercase !important',
    },
}));

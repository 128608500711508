import { TextField } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'core/contexts/localization';

const TextInput = ({
  meta: { touched, error },
  input: { ...inputProps },
  ...props
}) => {
  const { t: translate } = useTranslation();

  const [maxCharacters, setMaxCharacters] = useState(props.maxCharacters);
  const [characters, setCharacters] = useState(props.initialCharacters);

  console.log('PROPS====', props, 'touched', touched);

  // if (props.textArea) {
  //   props = { ...props, multiline: true, rows: props.textArea.rows };
  //   console.log("NEW PROPS ====", props);
  // }

  return (
    <TextField
      {...inputProps}
      {...props}
      onChange={(event) => {
        // console.log("event==", event, "value==", event.target.value)
        setCharacters(event.target.value.length || 0);
        if (props.onFieldChange) props.onFieldChange(event.target.value);
        inputProps.onChange(event);
      }}
      error={characters > maxCharacters}
      helperText={
        characters > maxCharacters &&
        `${translate('profile.maximum_characters')} 
            ${maxCharacters}. ${translate(
          'profile.typed_characters'
        )} ${characters}`
      }
      fullWidth
      variant="outlined"
    />
  );
};

TextInput.displayName = 'TextInput';

export default TextInput;

import React, { useState, useEffect, useCallback } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDebounce } from '../../utils/useDebounce';
import debounce from 'lodash.debounce';


const Lookup = ({
    label,
    fetchFunction,
    optionsProp,
    value, 
    displayValue, 
    selectValue,
    onChange,
    menuItemProps,
    hasError,
    errorMessage,
}) => {
    const [options, setOptions] = useState(optionsProp || []);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchOptions = async (searchText) => {
        // if (searchText.length < 3) {
        //     return; 
        // }
        setLoading(true);
        try {
            const fetchedOptions = await fetchFunction(searchText);
            setOptions(fetchedOptions || []);
        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

    useEffect(() => {
        if (inputValue) {
            debouncedFetchOptions(inputValue);
        }
    }, [inputValue, debouncedFetchOptions]);

    useEffect(() => {
        setOptions(optionsProp || []);
    }, [optionsProp]);

    // TODO: Good temporary solution.
    /* LATER: Replace autocomplete with new component
    * Split the lookup into entity architecture:
    * Lookup
    *   index.js --> main class component with generic/virtual functions
    *   /Entities --> Each entity will know how to look for themselves and how to display the labels and return clicked value 
    *       contract.js
    *       resource.js
    *       experience.js
    *       ...
    *  <Lookup entity={:name_of_entity} value={:value} displayLabel={:custom_display} />
    * 
    */

    return (
        <Autocomplete
            getOptionLabel={(option) => option[displayValue] ?? ''}
            options={options}
            loading={loading}
            value={value ? options.find((option) => option[selectValue] === value) : null}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(_, newValue) => {
                onChange(newValue ? newValue[selectValue] : null);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={!!hasError && hasError(value)}
                    helperText={!!hasError && hasError(value) ? errorMessage : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                />
            )}
            renderOption={(option, { selected }) => (
                <MenuItem {...menuItemProps} selected={selected}>
                    {option[displayValue]}
                </MenuItem>
            )}
        />
    );
};

export default Lookup;

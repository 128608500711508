import { Grid, IconButton, Button, TextField } from '@material-ui/core';
import { GooglePlacesAutocomplete } from './components/placesAutocomplete';
import { useTranslation } from 'core/contexts/localization';

export const Location = ({ ...props }) => {
    const { t: translate } = useTranslation();

    return (
        <>
            <Grid
                item
                container
                itemAlign={'center'}
                xs={12}
                style={{ height: '100px', alignItems: 'center' }}>
                <Grid
                    item
                    itemAlign={'center'}
                    style={{ textAlign: 'center' }}
                    xs={3}>
                    <label id="slide-modal-video-label">
                        {translate(
                            'experienceProfile.saveModal.location.title'
                        )}
                    </label>
                </Grid>
                <Grid item itemAlign={'center'} xs={9}>
                    <GooglePlacesAutocomplete
                        parseDataToSet={props.parseDataToSet}
                    />
                </Grid>
            </Grid>
        </>
    );
};

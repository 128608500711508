import React, { useContext, useState, useEffect, useCallback } from 'react';

const PaymentContext = React.createContext(null);

export const PaymentProvider = ({ children }) => {
  const [mercadoPago, setMercadoPago] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [checkoutExperiences, setCheckoutExperiences] = useState([]);

  const initialFormErrors = {
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
    phoneNumberCountryCode: true,
    country: true,
  };

  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const canSubmitForm = (checkingFromPayment = false) => {
    const formHasErrors = Object.values(formErrors).some(
      (errorValue) => errorValue
    );

    if (!checkingFromPayment) {
      setSubmitted(true);
    }

    if (formHasErrors) {
      console.log('FORM HAS ERRORS', formHasErrors);
      return false;
    }
    console.log('SUBMIT===', userData);
    return true;
  };

  const userDataInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    phoneNumberCountryCode: '',
    country: '',
  };

  const [userData, setUserData] = useState(userDataInitialValues);

  const resetForm = () => {
    setUserData(userDataInitialValues);
    setFormErrors(initialFormErrors);
    setSubmitted(false);
  };

  const setUserEmail = (email) => {
    setUserData({ ...userData, email });
  };

  const getUserEmail = () => {
    console.log('user data === ', userData);
    if (!userData.email) {
      throw new Error('User email is not defined');
    }
    return userData.email;
  };

  return (
    <PaymentContext.Provider
      value={{
        mercadoPago,
        setMercadoPago,
        userData,
        setUserData,
        resetForm,
        canSubmitForm,
        submitted,
        setSubmitted,
        checkoutExperiences,
        setCheckoutExperiences,
        formErrors,
        setFormErrors,
        getUserEmail,
        setUserEmail,
      }}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.displayName = 'PaymentProvider';

export const usePayment = () => useContext(PaymentContext);

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    experienceTitle: {
        color: '#4F4F4F !important',
        fontSize: '32px',
        fontWeight: '700',
        fontFamily: 'Mina',
        [theme.breakpoints.down('xs')]: {
            fontSize: '32px',
        },
    },
    buttonsContainer: {
        // width: '100%',
        // display: 'flex',
        // alignItems: 'center',
        // padding: '20px 0',
        // justifyContent: 'space-around',
        // [theme.breakpoints.down('xs')]: {
        //     display: 'block',
        //     padding: '20px 0',
        // },
        [theme.breakpoints.up('md')]: {
            minHeight: 200,
        },
    },
    homeButton: {
        boxShadow: '5px 10px 23px 0px #00000026',
        // [theme.breakpoints.up('md')]: {
        display: 'grid !important',
        width: '200px',
        padding: '30px !important',
        gap: '10px',
        height: '120px !important',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%',
        //     margin: '10px 0',
        // },
    },
    buttonIcon: {
        height: '40px',
        margin: 'auto',
        // [theme.breakpoints.down('sm')]: {
        //     height: '25px',
        //     marginRight: '20px',
        // },
    },
    buttonText: {
        paddingTop: 15,
        color: 'white',
    },
    conteinerGrid: {
        display: 'flex',
        padding: '60px',
        alignItems: 'center',
        gap: '30px',
    },
    liveButtonWrapper: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '25px',
            marginBottom: '25px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '25px',
            marginBottom: '25px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '25px',
            marginBottom: '25px',
            textAlign: 'center',
        },
    },
    goLiveButton: {
        width: '120px',
        padding: '10px',
        borderRadius: '4px',
        background: '#5fb6d9',
        fontFamily: 'DM Sans',
        color: '#fff',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '20px',
    },

    profile: {
        backgroundColor: '#71ba6c',
    },
    scheduling: {
        backgroundColor: 'rgb(116 216 133 / 25%)',
    },
    bookings: {
        backgroundColor: 'rgb(191 124 18 / 25%)',
    },
    saveModal: {
        padding: '14px',
    },
    smTitle: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '23px',
        display: 'flex',
        alignItems: 'center',
    },
    smDivider: {
        width: '95%',
        marginTop: '9px',
        borderBottom: 'solid 0.5px #4F4F4F',
        background: 'transparent',
        textAlign: 'center',
        opacity: 0.75,
    },
    smMessage: {
        width: '70%',
        textAlign: 'center',
        paddingTop: '36px',
        paddingBottom: '36px',
        margin: '0 auto',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        color: '#828282',
    },
    smFooter: {
        height: '36px',
    },
    smCancelButton: {
        float: 'right',
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smSaveButton: {
        float: 'right',
        paddingTop: '9px',
        marginLeft: '14px',
        background: '#2F80ED',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    container: {
        padding: theme.spacing(2),
    },
    card: {
        // width: '90%',
        margin: 'auto',
        marginTop: 50,
        border: '1px solid #e4e4e4 !important',
        boxShadow:
            '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05) !important',
    },
    optionCard: {
        margin: '60px 30px !important',
        borderRadius: '6px !important',
        // & .ant-card-head-title {
        // font-size: 20px !important;
        // font-weight: bold !important;
        // }
        marginTop: '10px !important',
    },
}));

import React from 'react';
import 'react-phone-input-2/lib/material.css';
import './styles.css';
import ActivePhoneNumber from './Active';
import DisabledPhoneNumber from './Disabled';

const PhoneNumber = ({
  disabled = false,
  initialCountry,
  label,
  onFieldChange,
  ...props
}) => {
  const render = () => {
    const initialValue = props.input.value;
    if (disabled) {
      return (
        <DisabledPhoneNumber
          value={initialValue}
          initialCountry={initialCountry}
          label={label}
        />
      );
    }
    return (
      <ActivePhoneNumber
        value={initialValue}
        initialCountry={initialCountry}
        label={label}
        onFieldChange={onFieldChange}
      />
    );
  };

  return <>{render()}</>;
};

export default PhoneNumber;

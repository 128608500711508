import { fetch, Method } from 'core/api/fetch';

const TriperRoutes = {
  update: '/api/v1.1.0/triper',
  updateAvatar: (triper) => `/api/v1.1.0/triper/${triper}/image`,
};

export const TriperService = {
  update: async (info) => {
    try {
      const { data } = await fetch(TriperRoutes.update, {
        method: Method.PATCH,
        authenticated: true,
        data: info,
      });

      return Array.isArray(data) ? data[0] : false;
    } catch (err) {
      throw new Error(err);
    }
  },

  updateAvatar: async (id, image) => {
    try {
      const formData = new FormData();
      image =
        image.type && image.type !== ''
          ? image
          : image.slice(0, image.size, 'image/png');
      formData.append('images', image, image.name ?? 'avatar.png');
      await fetch(TriperRoutes.updateAvatar(id), {
        method: Method.POST,
        authenticated: true,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      throw new Error(err);
    }
  },
};

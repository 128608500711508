import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';
import { parseExperienceToModel } from 'core/api/parser/experienceInfo';
import { parseExperienceToObject } from 'core/api/parser/experienceInfo';
import { AuthService } from '../auth';

const experienceResourceRoutes = {
    addResource: (idExperience) =>
        `/api/v1.1.0/experience/${idExperience}/resource`,
    updateResource: (idExperience, idResource) =>
        `/api/v1.1.0/experience/${idExperience}/resource/${idResource}`,
    deleteResource: (idExperience, idResource) =>
        `/api/v1.1.0/experience/${idExperience}/resource/${idResource}`,
    getResourceExperience: (idExperience) =>
        `/api/v1.1.0/experience/${idExperience}/resource`,
    getResources: () => `/api/v1.1.0/experiencer/resource`,
    addResourceCategory: (idExperience) =>
        `/api/v1.1.0/experience/${idExperience}/resourcecategory`,
    updateResourceCategory: (idExperience, idResource) =>
        `/api/v1.1.0/experience/${idExperience}/resourcecategory/${idResource}`,
    deleteResourceCategory: (idExperience, idResource) =>
        `/api/v1.1.0/experience/${idExperience}/resourcecategory/${idResource}`,
    getResourceCategoryExperience: (idExperience, options = {}) =>
        `/api/v1.1.0/experience/${idExperience}/resourcecategory${
            options.getResources ? '?getResources=true' : ''
        }`,
    getResourceCategories: () => `/api/v1.1.0/experiencer/resourcecategory`,
};

const buildGetResourcesQueryParams = (params) => {
    const queryParams = [];

    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            queryParams.push(
                `${key}=${Array.isArray(value) ? `[${value}]` : value}`
            );
        }
    });

    return queryParams.length > 0 ? `&${queryParams.join('&')}` : '';
};

export const ExperienceResourceService = {
    getResourceExperience: async (expId) => {
        try {
            const { data } = await fetch(
                experienceResourceRoutes.getResourceExperience(expId),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return isEmpty(data) ? { resources: [], total: 0 } : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    getResources: async () => {
        try {
            const args = [];
            const { data } = await fetch(
                `${experienceResourceRoutes.getResources()}${buildGetResourcesQueryParams(
                    {}
                )}`,
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return data.resources;
        } catch (err) {
            throw new Error(err);
        }
    },
    addResource: async (idExperience, newData) => {
        try {
            const url = experienceResourceRoutes.addResource(idExperience);
            const { data } = await fetch(url, {
                method: Method.POST,

                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(newData),
            });
            return data;
        } catch (err) {
            console.error('Error sending data:', err);
            throw err;
        }
    },
    updateResource: async (idExperience, newData) => {
        try {
            const url = experienceResourceRoutes.updateResource(
                idExperience,
                newData.id
            );
            const { data } = await fetch(url, {
                method: Method.PATCH,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(newData),
            });
            return data;
        } catch (err) {
            console.error('Error sending data:', err);
            throw err;
        }
    },
    deleteResource: async (expId, resourceId) => {
        const resArr = [];
        try {
            await fetch(
                experienceResourceRoutes.deleteResource(expId, resourceId),
                {
                    method: Method.DELETE,
                    authenticated: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then((res) => {
                resArr.push(res);
            });
            return resArr;
        } catch (err) {
            resArr.push(err);
            return resArr;
        }
    },

    getResourceCategoryExperience: async (expId, options = {}) => {
        try {
            const { data } = await fetch(
                experienceResourceRoutes.getResourceCategoryExperience(
                    expId,
                    options
                ),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return isEmpty(data) ? { resources: [], total: 0 } : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    getResourceCategories: async () => {
        try {
            const args = [];
            const { data } = await fetch(
                `${experienceResourceRoutes.getResourceCategories()}${buildGetResourcesQueryParams(
                    {}
                )}`,
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return data.resourceCategories;
        } catch (err) {
            throw new Error(err);
        }
    },
    addResourceCategory: async (idExperience, newData) => {
        try {
            const url =
                experienceResourceRoutes.addResourceCategory(idExperience);
            const { data } = await fetch(url, {
                method: Method.POST,

                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(newData),
            });
            return data;
        } catch (err) {
            console.error('Error sending data:', err);
            throw err;
        }
    },
    updateResourceCategory: async (idExperience, newData) => {
        try {
            const url = experienceResourceRoutes.updateResourceCategory(
                idExperience,
                newData.id
            );
            const { data } = await fetch(url, {
                method: Method.PATCH,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(newData),
            });
            return data;
        } catch (err) {
            console.error('Error sending data:', err);
            throw err;
        }
    },
    deleteResourceCategory: async (expId, resourceCategoryId) => {
        const resArr = [];
        try {
            await fetch(
                experienceResourceRoutes.deleteResourceCategory(
                    expId,
                    resourceCategoryId
                ),
                {
                    method: Method.DELETE,
                    authenticated: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            ).then((res) => {
                resArr.push(res);
            });
            return resArr;
        } catch (err) {
            resArr.push(err);
            return resArr;
        }
    },
};

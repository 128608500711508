import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: theme.spacing(),
    color: theme.palette.colors.mineShaft,
    boxShadow:
      '0px 0px 8px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(255, 255, 255, 0.12)',
  },
  icon: {
    color: theme.palette.colors.mineShaft,
  },
}));

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import {
    Grid,
    ListItem,
    ListItemText,
    ListItemIcon,
    CircularProgress,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useExperiences } from 'core/contexts/experience';
import { useAuth } from 'core/contexts/auth';

const ExperiencesList = () => {
    const classes = useStyles();
    const history = useHistory();
    const { role, setRole, userInfo, getUserInfoSync } = useAuth();
    const { experiences, loading, getExperiencesUpdated } = useExperiences();

    // for debugging purposes only
    // useEffect(() => {
    //   console.log('Current state: ');
    //   const args = [
    //     ['loading: ', loading],
    //     ['experiences: ', experiences],
    //   ];
    //   console.table(args);
    // }, [loading, experiences]);

    useEffect(() => {
        async function fetchData() {
            return await getUserInfoSync();
        }
        fetchData().then((usr) => getExperiencesUpdated(usr?.experiencer?.id));
    }, []);

    return loading ? (
        <CircularProgress />
    ) : (
        experiences?.map((experience) => (
            <Grid key={experience?.id} item xs={12} sm={6} md={4}>
                <ListItem
                    button
                    className={classes.listItem}
                    onClick={() =>
                        history.push(`/experience/${experience?.id}`)
                    }>
                    <ListItemText primary={experience?.name} />
                    <ListItemIcon>
                        <ChevronRight className={classes.icon} />
                    </ListItemIcon>
                </ListItem>
            </Grid>
        ))
    );
};

ExperiencesList.displayName = 'ExperiencesList';

export default ExperiencesList;

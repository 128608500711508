import { isEmpty } from 'core/packages/utils';

import { parseLocationToModel, parseLocationToObject } from './location';
import { parseModusToModel, parseModusToObject } from './modus';

export const parseTripLocationToModel = (tripLocation) => ({
    id: tripLocation?.id,
    from: tripLocation?.start,
    to: tripLocation?.end,
    location: parseLocationToModel(tripLocation?.location),
    moduses: !isEmpty(tripLocation?.modus)
        ? tripLocation?.modus.map(parseModusToModel)
        : [],
});

export const parseTripLocationToObject = (tripLocation) => ({
    osmId: tripLocation?.osmId,
    start: tripLocation?.datePicker?.[0]?._d,
    end: tripLocation?.datePicker?.[1]?._d,
    modus: !isEmpty(tripLocation?.moduses)
        ? tripLocation?.moduses.map(parseModusToObject)
        : [],
});

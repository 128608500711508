import { fetch, Method } from 'core/api/fetch';
import {
  parseLoginToObject,
  parseRegisterToObject,
} from 'core/api/parser/auth';
import { parseUserToModel } from '../parser/auth';

const AuthRoutes = {
  login: '/auth/login',
  logout: '/auth/logout',
  me: '/api/v1.1.0/user',
  signUp: '/api/v1.0.0/user/create_user',
  forgottenPassword: '/auth/password',
  verifyPassword: (code) => `/auth/password/verify/${code}`,
};

export const AuthService = {
  login: async ({ data }) => {
    try {
      return await fetch(AuthRoutes.login, {
        method: Method.POST,
        data: parseLoginToObject(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      throw new Error(err);
    }
  },
  logout: async ({ accessToken }) => {
    // no se utiliza el accessToken pues se extrae con la funcion fetch()
    try {
      const authMessage = await fetch(
        AuthRoutes.logout,
        {
          authenticated: true,
          method: Method.GET,
        }
        // accessToken
      );

      return authMessage;
    } catch (err) {
      return null;
    }
  },
  me: async () => {
    const { data } = await fetch(AuthRoutes.me, {
      method: Method.GET,
      authenticated: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return parseUserToModel(data[0]);
  },
  refreshToken: async () => {},
  changePassword: async () => {},
  signUp: async ({ data }) => {
    try {
      const { status } = await fetch(AuthRoutes.signUp, {
        method: Method.POST,
        data: parseRegisterToObject(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return status;
    } catch (err) {
      throw new Error(err);
    }
  },
  forgetPassword: async (email) => {
    try {
      const data = await fetch(AuthRoutes.forgottenPassword, {
        method: Method.POST,
        data: {
          email,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data === 'Accepted';
    } catch (err) {
      throw new Error(err);
    }
  },
  verifyPassword: async (code) => {
    try {
      console.log('VERIFYING PASSWORD');
      const data = await fetch(AuthRoutes.verifyPassword(code), {
        method: Method.GET,
      });
      return data === 'OK';
    } catch (err) {
      throw new Error(err);
    }
  },
  resetPassword: async (code, password) => {
    try {
      const data = await fetch(AuthRoutes.forgottenPassword, {
        method: Method.PUT,
        data: {
          code,
          password,
        },
      });
      console.log(`responseee ${data}`);
      return data === 'OK';
    } catch (err) {
      throw new Error(err);
    }
  },
};

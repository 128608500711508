import React, { useContext, useState } from 'react';
import { isEmpty } from 'core/packages/utils';

import { ModusService } from 'core/api/services/modus';

const ModusesContext = React.createContext(null);

export const ModusesProvider = ({ children }) => {
    const [moduses, setModuses] = useState([]);
    const [loading, setLoading] = useState(true);

    const getModuses = async () => {
        try {
            if (isEmpty(moduses)) {
                setLoading(true);
                const modusesData = await ModusService.get();

                if (!modusesData) {
                    throw new Error();
                }

                setModuses(modusesData);
            }

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    return (
        <ModusesContext.Provider
            value={{
                moduses,
                getModuses,
                loading,
            }}>
            {children}
        </ModusesContext.Provider>
    );
};

ModusesProvider.displayName = 'ModusesProvider';

export const useModuses = () => useContext(ModusesContext);

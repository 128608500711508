import { fetch, Method } from 'core/api/fetch';
import { parseBookingToObject } from '../parser/experiencer';

const ResourceRoutes = {
    getResources: () => `/api/v1.1.0/experiencer/resource`,
    postResource: () => `/api/v1.1.0/experiencer/resource`,
    getReource: (id) => `/api/v1.1.0/experiencer/resource/${id}`,
    updateResource: (id) => `/api/v1.1.0/experiencer/resource/${id}`,
    postCategory: () => `/api/v1.1.0/experiencer/resourcecategory`,
    getCategories: () => `/api/v1.1.0/experiencer/resourcecategory`,
    getCategory: (id) => `/api/v1.1.0/experiencer/resourcecategory/${id}`,
    updateCategory: (id) => `/api/v1.1.0/experiencer/resourcecategory/${id}`,
};

const buildGetResourcesQueryParams = (params) => {
    const queryParams = [];

    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            queryParams.push(
                `${key}=${Array.isArray(value) ? `[${value}]` : value}`
            );
        }
    });

    return queryParams.length > 0 ? `&${queryParams.join('&')}` : '';
};

export const parseResourceToObject = (data) => ({
    name: data.name,
    email: data.email,
    mobilePhone: data.mobilePhone,
    resourceCategoryId: data.resourceCategoryId,
});

export const ResourceService = {
    getResources: async () => {
        try {
            const args = [];
            const { data } = await fetch(
                `${ResourceRoutes.getResources()}${buildGetResourcesQueryParams(
                    {}
                )}`,
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return data.resources;
        } catch (err) {
            throw new Error(err);
        }
    },
    getResource: async (id) => {
        try {
            const { data } = await fetch(ResourceRoutes.getReource(id), {
                method: Method.GET,
                authenticated: true,
            });
            console.log({ data }, 'responseData getResource single');
            return !data || !data.length ? {} : data[0];
        } catch (err) {
            throw new Error(err);
        }
    },
    updateResource: async (resourceData) => {
        try {
            const { data } = await fetch(
                ResourceRoutes.updateResource(resourceData.id),
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: resourceData,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            // return data;
            return Array.isArray(data) && data.length ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    postResource: async (info) => {
        try {
            const { data } = await fetch(ResourceRoutes.postResource(), {
                method: Method.POST,
                authenticated: true,
                data: info,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    postCategory: async (info) => {
        try {
            const { data } = await fetch(ResourceRoutes.postCategory(), {
                method: Method.POST,
                authenticated: true,
                data: info,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    getCategories: async (id) => {
        try {
            const { data } = await fetch(ResourceRoutes.getCategories(), {
                method: Method.GET,
                authenticated: true,
            });
            console.log({ data }, 'responseData getResource single');
            return !data ? {} : data.resourceCategories;
        } catch (err) {
            throw new Error(err);
        }
    },
    updateCategory: async ({ id, name }) => {
        try {
            const { data } = await fetch(ResourceRoutes.updateCategory(id), {
                method: Method.PATCH,
                authenticated: true,
                data: { name },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return data;
            // return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};

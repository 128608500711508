export const modalCollector = (data, dataTemp) => {
    Object.keys(data).map((dataKey) => {
        Object.keys(dataTemp).map((key) => {
            if (key === dataKey) {
                dataTemp[`${key}`] = data[`${key}`];
            }
        });
    });
};

export const descriptionCollector = (data, dataTemp) => {
    Object.keys(dataTemp).map((key) => {
        if (key === data.objName) {
            dataTemp[`${key}`] = data.value;
        }
    });
};

export const setDefaultData = (data, dataTemp) => {
    let newData = dataTemp;
    Object.keys(data).map((input, index) => {
        Object.keys(dataTemp).map((output) => {
            if (input === output) {
                newData[`${output}`] = data[`${Object.keys(data)[index]}`];
            }
        });
    });
    return newData;
};

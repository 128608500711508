import axios from 'axios';
import { isEmpty } from 'core/packages/utils';

import { getSession } from 'core/api/session';

//TODO Esto hay que cambiarlo cuando el endpoint funcione independientemente del país
const CoTripersRoutes = {
    search: (query) => `/api/v1.0.0/triper/cotriper/${query}`,
};

let cancelToken;

export const CoTripersService = {
    get: async (query) => {
        try {
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.');
            }
            cancelToken = axios.CancelToken.source();
            const authData = getSession(); // TODO : Review this url
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}${CoTripersRoutes.search(
                    query
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${authData.token}`,
                        'Content-Type': 'application/json',
                    },
                    cancelToken: cancelToken.token,
                }
            );

            return isEmpty(data?.data) ? [] : data.data;
        } catch (err) {
            throw new Error(err);
        }
    },
};

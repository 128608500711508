/* eslint-disable import/no-anonymous-default-export */
export default {
    primary: {
        main: '#363740',
        background: '#FFFFFF',
    },
    secondary: {
        main: '#9bc2d1 ',
        secondary: '#71BA6C',
        dark: '#557785',
        background: '#F6F6F6',
    },
    text: {
        primary: '#333',
        secondary: '#363740',
        white: '#fff',
    },
    colors: {
        alto: '#E0E0E0',
        frenchPass: '#B2CFFE',
        padua: '#B0E8B8',
        harvestGold: '#DAB673',
        scienceBlue: '#004CD0',
        white: '#FFFFFF',
        mineShaft: '#2F2F2F',
        persianGreen: '#009688',
    },
    utriper: {
        orange: '#E39348',
        green: '#A6ba62',
        lightgreen: '#C7DF75',
        darkgreen: '#84944E',
        yellow: '#F2c35c',
        lightblue: '#9bc2d1',
        darkblue: '#5d747d',
        blue: '#a5c8d6',
    },
    utriperGreen: {
        main: '#84944E',
    },
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    backButton: {
        height: 40,
        borderRadius: 4,
        padding: '0px 20px',
        width: '7rem',
    },
    buttons: {
        marginTop: '1rem',
        display: 'inline-flex',
        gap: '1rem',
        position: 'absolute',
        right: '20vw',
    },
    triperButton: {
        color: '#ffffff !important',
        lineHeight: '40px !important',
        height: '40px !important',
        textAlign: 'center !important',
        fontSize: '14px !important',
        borderRadius: '4px !important',
        border: 'none !important',
        // padding: '0 20px !important',
        cursor: 'pointer !important',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        backgroundColor: 'transparent',
    },

    bgBlue300: {
        backgroundColor: '#5fb6d9 !important',
        '&:hover': {
            backgroundColor: '#498BA6 !important',
        },
    },
}));

import React, { useState, useEffect } from 'react';
import { Grid, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CapacityIcon from 'assets/icons/capacity.svg';

import FullCalendar from 'core/libs/core-ui/components/Fullcalendar';

import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';

import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';
import { ExperiencerService } from 'core/api/services/experiencer';

import { formatMoney } from 'core/packages/format';

import { useCalendarPage } from './hook';
import { useStyles } from './styles';

const CalendarPage = ({ expId, tableView, ...props }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const {
        date: { value, onChange },
        calendar: {
            start,
            end,
            range,
            ref,
            onSelect,
            onDismiss,
            onSave,
            tz,
            setTz,
        },
        popOver: { show, position, setOpen },
        isSmall,
        // onEventClick,
    } = useCalendarPage();

    const { getUserInfoSync } = useAuth();

    const [subExpId, setSelection] = useState(0);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [experienceData, setExperienceData] = useState();
    const [calendarRange, setCalendarRange] = useState({});
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        if (calendarRange && subExpId) {
            ExperiencerService.getBookingsCalendar({
                id: expId,
                range: calendarRange,
            }).then((res) => {
                setBookings(res);
            });
        } else {
            setBookings([]);
        }
    }, [calendarRange, subExpId, tableView]);

    useEffect(() => {
        if (expId) {
            ExperienceInfoService.get(expId)
                .then(async (expData) => {
                    const userData = await getUserInfoSync();
                    if (
                        !userData.experiencer ||
                        !userData.experiencer.id ||
                        expData.experiencerId != userData.experiencer.id
                    ) {
                        throw new Error(
                            'Cannot view another user experience calendar!'
                        );
                    }
                    setExperienceData(expData);
                    const expDetailId = expData?.expDetail?.length
                        ? expData?.expDetail[0].id
                        : 0;
                    setSelection(expDetailId);
                })
                .catch((err) => {
                    alert(err);
                    history.replace('/home');
                });
        }
    }, [expId]);

    useEffect(() => {
        if (bookings) {
            setCalendarEvents(
                bookings
                    ?.map((booking) => [
                        {
                            extendedProps: {
                                price: booking.price,
                                currency: booking.currency,
                                capacity: booking.capacity,
                                scheduleId: booking.scheduleId,
                                ocupancy: booking.ocupancy,
                            },
                            start: booking.start,
                            end: booking.end,
                            color:
                                booking.availability < 25.0
                                    ? 'red'
                                    : booking.availability < 50.0
                                    ? '#FB8F1D'
                                    : booking.availability < 75.0
                                    ? '#FFBB0C'
                                    : 'green',
                        },
                    ])
                    ?.map((booking) => booking[0])
            );
        }
    }, [bookings]);

    function renderEventContent(eventInfo) {
        return (
            <div style={{ padding: 5 }}>
                <div>
                    <b>{eventInfo.timeText}</b>
                </div>
                <div>
                    <b className="d-flex">
                        <span className="mr-2">
                            {eventInfo.event.extendedProps?.currency}
                        </span>
                        <span>
                            {formatMoney(eventInfo.event.extendedProps?.price)}
                        </span>
                    </b>
                </div>
                <div>
                    <img
                        alt=""
                        src={CapacityIcon}
                        width={20}
                        style={{ marginRight: 5 }}
                    />
                    <b>
                        {eventInfo.event.extendedProps?.ocupancy
                            ? `${eventInfo.event.extendedProps?.ocupancy}/`
                            : ``}
                        {eventInfo.event.extendedProps?.capacity}
                    </b>
                </div>
            </div>
        );
    }

    return (
        <>
            <Grid
                container
                justifyContent="center"
                style={{ marginBottom: '25px' }}>
                <div className={classes.availabilityInfo}>
                    <span
                        className="bold text-18"
                        style={{
                            fontWeight: 'bold !important',
                            fontSize: '18px !important',
                        }}>
                        {translate('calendar.availability')}
                    </span>
                    <div>
                        <Chip
                            className={classes.chip}
                            label={`-${25} %`}
                            style={{ backgroundColor: 'red' }}
                        />
                        <Chip
                            className={classes.chip}
                            label={`${25} % - ${50} %`}
                            style={{ backgroundColor: '#FB8F1D' }}
                        />
                        <Chip
                            className={classes.chip}
                            label={`${50} % - ${75} %`}
                            style={{ backgroundColor: '#FFBB0C' }}
                        />
                        <Chip
                            className={classes.chip}
                            label={`+ ${75} %`}
                            style={{ backgroundColor: 'green' }}
                        />
                    </div>
                </div>
            </Grid>
            <Grid container>
                <FullCalendar
                    useCalendarPage={useCalendarPage}
                    schedules={calendarEvents}
                    renderEventContent={renderEventContent}
                    setCalendarRange={setCalendarRange}
                    value={value}
                    onChange={onChange}
                    title={experienceData?.name}
                    tz={tz}
                    setTz={setTz}
                    start={start}
                    end={end}
                    range={range}
                    ref={ref}
                    onSelect={onSelect}
                    onDismiss={onDismiss}
                    isSmall={isSmall}
                    showCreateEventeButton={false}
                    onEventClick={(event) => {
                        const { startStr, endStr } = event.event;
                        history.push({
                            pathname: `/booking/${startStr.substring(
                                0,
                                startStr.lastIndexOf('T')
                            )}/${expId}/${subExpId}`,
                            state: {
                                scheduleId:
                                    event.event._def.extendedProps.scheduleId,
                                start: startStr,
                                end: endStr,
                                formattedFrom: startStr.substring(
                                    0,
                                    startStr.lastIndexOf('T')
                                ),
                            },
                        });
                    }}
                />
            </Grid>
        </>
    );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;

import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import { Form, Field } from 'react-final-form';
import { useStyles } from './styles';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import { toast } from 'react-toastify';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Lookup from 'core/libs/core-ui/components/Lookup';
import { ExperienceResourceService } from 'core/api/services/ExperienceInfo/expResource';
import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import { useAuth } from 'core/contexts/auth';

const CategoryToExperienceAddModal = ({ experienceId, getResources, handleSubmit, onClose, ...props }) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    
    const [dataResources, setDataResources] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [whatsappNotification, setwhatsappNotification] = useState(false);
    const [emailNotificationCheckBox, setEmailNotificationCheckBox] = useState(false);

    const fetchResources = async () => {
        try {
            const dataResourcesResponse =
                await getResources();
            const formattedResources = dataResourcesResponse.map(
                (resource) => ({
                    id: resource.id,
                    resource: resource.name,
                })
            );
            setDataResources(formattedResources);
        } catch (error) {
            console.error('Error fetching resources:', error);
            return [];
        }
    };

    const onFormSubmit = async (values) => {
        
        const newData = [
            {
                resourceId: categorySelected,
                resourceCategoryId: categorySelected,
                notificationEmail: false,
                notificationWhatsapp: false,
            },
        ];
        try {
            const result = await handleSubmit(experienceId,newData);
            if(result) {
                toast.success(translate('resource.resource_success'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            } else {
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            onClose();
        } catch (error) {
            console.error(translate('resource.resource_error'), error);
            toast.error(translate('resource.resource_error'), {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const required = (value) => {
        return value ? undefined : 'Required';
    };

    const handleSelectionChange = (selectedValue) => {
        setCategorySelected(selectedValue);
    };

    useEffect(() => {
        fetchResources();
    },[])

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.add_resource')}
            </Typography>
            <Divider />
            <Grid>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{}}
                    render={({
                        input,
                        meta,
                        handleSubmit,
                        valid,
                        pristine,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <Field
                                    name="type"
                                    component={Lookup}
                                    label={translate('resource.add_resource')}
                                    fetchFunction={fetchResources}
                                    displayValue="resource"
                                    optionsProp={dataResources}
                                    selectValue="id"
                                    errorMessage={translate(
                                        'error_message.required'
                                    )}
                                    hasError={(selectedValue) => selectedValue}
                                    onChange={(newValue) => {
                                        handleSelectionChange(newValue);
                                    }}
                                    menuItemProps={{
                                        style: {
                                            color: 'black',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                    }}
                                />

                                {/* <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Email Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setEmailNotificationCheckBox(value);
                                    }}
                                    disabled={!categorySelected?.email}
                                />

                                <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Whatsapp Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setwhatsappNotification(value);
                                    }}
                                    disabled
                                /> */}

                                <Box className={classes.buttonContainer}>
                                    <Button
                                        disabled={!valid}
                                        type="submit"
                                        className={classes.createButton}>
                                        {translate(
                                            'trip.create.buttons.add_co_triper'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    )}></Form>
            </Grid>
        </>
    );
};

export default CategoryToExperienceAddModal;

export const parseContractToModel = (contract) => ({
    hasQR: contract.isQR ? 'Yes' : 'No',
    ...contract,
});

export const parseContractToObject = (data) => ({
    code: data.id.toString(16),
    qrURL: data.isQR ? `/static/contracts/${data.id}/qr.png` : null,
    hasQR: data.isQR ? 'Yes' : 'No',
    ...data,
});

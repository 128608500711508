import { useState, useEffect } from 'react';
import { Grid, TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {
    payment_methods,
    PAYMENT_METHODS,
    getTranslatedName as getPaymentMethodsTranslatedName,
} from 'core/libs/import/PaymentMethods';
import { useStyles } from './styles';

const BookingPercentage = ({ experienceInfoTemp }) => {
    const classes = useStyles();

    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
    })((props) => <Checkbox color="default" {...props} />);

    const isPaymentMethodChecked = (id) => {
        return experienceInfoTemp.paymentMethods.some(
            (method) => method.id === id
        );
    };

    const initialOptions = [
        {
            id: PAYMENT_METHODS.CASH.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.CASH.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.CASH.id),
        },
        {
            id: PAYMENT_METHODS.CREDIT_CARD.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.CREDIT_CARD.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.CREDIT_CARD.id),
        },
        {
            id: PAYMENT_METHODS.TRANSFER.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.TRANSFER.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.TRANSFER.id),
        },
        {
            id: PAYMENT_METHODS.MERCADO_PAGO.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.MERCADO_PAGO.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.MERCADO_PAGO.id),
        },
        {
            id: PAYMENT_METHODS.PAYPAL.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.PAYPAL.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.PAYPAL.id),
        },
    ];

    const [options, setOptions] = useState(initialOptions);

    const [percentage, setPercentage] = useState(
        experienceInfoTemp.payNowPercentage ?? 0
    );

    return (
        <div>
            <span className={classes.text}>
                {translate('experienceProfile.saveModal.booking.subtitle1')}
            </span>
            <div className={classes.box}>
                <TextField
                    className={classes.form}
                    id="booking-percentage"
                    value={percentage}
                    onChange={(e) => {
                        let value = 0;
                        const targetValue = parseInt(e.target.value);
                        if (targetValue > 100) {
                            value = 100;
                        } else if (targetValue < 0) {
                            value = 0;
                        } else {
                            value = targetValue;
                        }
                        experienceInfoTemp.payNowPercentage = value;
                        setPercentage(value);
                    }}
                    variant="outlined"
                    type="number"
                    inputProps={{
                        min: 0,
                        max: 100,
                        step: 1,
                        'aria-label': 'booking-percentage',
                    }}
                />
                <div className={classes.percentage}>%</div>
            </div>
            <span className={classes.text}>
                {translate('experienceProfile.saveModal.booking.subtitle2')}
            </span>
            <span className={classes.text}>
                {translate('experienceProfile.saveModal.booking.subtitle3')}
            </span>
            <div>
                {options.map((entry, index) => {
                    return (
                        <div className={classes.checkbox}>
                            <GreenCheckbox
                                key={index}
                                checked={entry.checked}
                                color="#2F80ED"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                                onClick={(e) => {
                                    const auxOptions = [...options];

                                    // if it was checked, uncheck it => remove it
                                    if (auxOptions[index].checked) {
                                        auxOptions[index].checked = false;
                                        experienceInfoTemp.paymentMethods =
                                            experienceInfoTemp.paymentMethods.filter(
                                                (method) =>
                                                    method.id !== entry.id
                                            );
                                    } else {
                                        auxOptions[index].checked = true;
                                        experienceInfoTemp.paymentMethods.push({
                                            id: entry.id,
                                            label: entry.label,
                                        });
                                    }
                                    console.log(
                                        experienceInfoTemp.paymentMethods
                                    );
                                    setOptions(auxOptions);
                                }}
                            />
                            <span>{entry.label}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BookingPercentage;

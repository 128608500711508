import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import {
    AppBar as MUIAppBar,
    IconButton,
    useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import MoneyDropDown from '../MoneyDropDown';
import LanguageDropDown from '../LanguageDropDown';

import { useAppBar } from './hook';
import { useTranslation } from 'core/contexts/localization';
import { ROUTES } from 'core/routing/routes';

// import '../../styles/base/navbar.scss';

const AppBar = ({ toggleMenu, open, isHome }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { t: translate } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { classes, role, setRole, onBecomeClick } = useAppBar(isHome);

    // console.log('showww ===', showBecomeButton);
    return (
        <MUIAppBar
            elevation={0}
            className={clsx(classes.appBar, open && classes.appBarShift)}>
            <div className={classes.toolbar}>
                {isMobile ? (
                    <IconButton
                        color="white"
                        onClick={toggleMenu}
                        edge="start"
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                ) : null}

                <span className={classes.role}>
                    {
                        /* {showBecomeButton ? ( 
            // <Button
            //   onClick={onBecomeClick}
            //   className={clsx(classes.highlightRole, classes.roleText)}>
            //   {translate('layout.appBar.become')}
            // </Button>
          // ) : ( */
                        <>
                            {location.pathname === '/home' ? (
                                <>
                                    <LanguageDropDown />
                                    <MoneyDropDown />
                                    {/* <Button
                    variant={role === 'triper' ? 'contained' : 'outlined'}
                    size={'small'}
                    onClick={() => setRole('triper')}
                    color="secondary"
                    className={clsx(
                      role === 'triper' && classes.highlightRole,
                      classes.roleText
                    )}>
                    Triper
                  </Button>
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.roleSeparator}
                  />
                  <Button
                    size={'small'}
                    variant={role === 'experiencer' ? 'contained' : 'outlined'}
                    color="secondary"
                    style={{ fontSize: isMobile ? 10 : '' }}
                    onClick={() => setRole('experiencer')}
                    className={clsx(
                      role === 'experiencer' && classes.highlightRole,
                      classes.roleText
                    )}>
                    {translate('layout.appBar.touroperator')}
                  </Button> */}
                                </>
                            ) : null}
                        </>
                    }
                </span>
            </div>
        </MUIAppBar>
    );
};

AppBar.displayName = 'AppBar';

export default AppBar;

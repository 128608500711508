import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';
import { parseExperienceToModel } from 'core/api/parser/experienceInfo';
import { parseExperienceToObject } from 'core/api/parser/experienceInfo';
import { AuthService } from '../auth';

const experienceInfoRoutes = {
    save: '/api/v1.1.0/experience',
    update: '/api/v1.1.0/experience',
    getAll: '/api/v1.1.0/experience',
};

export const ExperienceInfoService = {
    get: async (expId) => {
        try {
            const { data } = await fetch(
                `${experienceInfoRoutes.getAll}/${expId}`,
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            // const user = await AuthService.me();
            // if (
            //   isEmpty(user.experiencer)
            //   ||
            //   data[0].experiencerId !== user.experiencer.id
            // ) {
            //   throw new Error(
            //     'Experiencer cannot edit this experience as he does not owns it!'
            //   );
            // }
            //console.log(data, "<--- load data0")
            return isEmpty(data) ? [] : parseExperienceToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    save: async (expId, newData) => {
        const resArr = [];
        try {
            // console.log(
            //   expId,
            //   '<-- exp id',
            //   parseExperienceToModel(newData),
            //   "<--- ON 'experienceInfo' POST SERVICE"
            // );
            await fetch(experienceInfoRoutes.save, {
                method: Method.POST,
                authenticated: true,
                data: parseExperienceToModel(newData),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                console.log(res, 'save res');
                resArr.push(res);
            });
            return resArr;
        } catch (err) {
            resArr.push(err);
            return resArr;
        }
    },
    update: async (expId, newData) => {
        const resArr = [];
        try {
            //console.log(
            //   expId,
            //   '<-- exp id',
            //   parseExperienceToModel(newData, expId),
            //   "<--- ON 'experienceInfo' PATH SERVICE"
            // );
            await fetch(`${experienceInfoRoutes.update}`, {
                method: Method.PATCH,
                authenticated: true,
                data: parseExperienceToModel(newData, expId),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                resArr.push(res);
            });
            return resArr;
        } catch (err) {
            resArr.push(err);
            return resArr;
        }
    },
};

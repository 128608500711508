import Layout from 'components/Layout';
// import { useAuth } from 'core/contexts/auth';
// import TriperHomePage from 'modules/Home/Triper';
import ExperiencerHomePage from 'modules/Home/Experiencer';

import { useStyles } from './styles';

const HomePage = () => {
    const classes = useStyles();

    return (
        <Layout contentClassName={classes.content} isHome={true}>
            {/* {role === 'triper' && <TriperHomePage />} */}
            <ExperiencerHomePage />
        </Layout>
    );
};

HomePage.displayName = 'HomePage';

export default HomePage;

import React from 'react';
import {
    IconButton,
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    FormControlLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';

const CategoryExperienceCard = ({resourceCategory, onUpdate, onDelete, ...props}) => {
    return (
        <Container>
            <Paper
                style={{
                    padding: 16,
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} container justifyContent='flex-end'>
                        <IconButton aria-label="delete" onClick={() => onDelete(resourceCategory.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                            <Typography variant="h2">{resourceCategory?.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid
                            container
                            spacing={1}
                            style={{ flexDirection: 'column' }}
                            display="flex">
                            <Grid item xs={12} sm={6}>
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox name="emailNotification" />
                                    }
                                    label="Todos disponiles"
                                /> */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <CheckboxComponent labelComponent={'Email Notification'} checked={resourceCategory?.notificationEmail || false} onChange={(value) => onUpdate({...resourceCategory, notificationEmail: value})} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CheckboxComponent labelComponent={'Whatsapp Notification'} checked={resourceCategory?.notificationWhatsapp || false} disabled/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default CategoryExperienceCard;

import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const Buttons = ({ disabledSend, sendEmail }) => {
    const { t: translate } = useTranslation();

    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.buttons}>
            <Button
                variant="outlined"
                className={classes.backButton}
                onClick={() => history.push('/')}>
                {translate('buttons.cancel')}
            </Button>
            <Button
                disabled={disabledSend}
                className={clsx(classes.triperButton, classes.bgBlue300)}
                onClick={() => {
                    sendEmail();
                }}>
                {translate('reset_password.send_email')}
            </Button>
        </div>
    );
};

export default Buttons;

import Layout from 'components/Layout';
// import { CircularProgress, useMediaQuery } from '@material-ui/core';
// import ResourcePageComponent from './componenets/ResourcePage';
import { Grid } from '@material-ui/core';

import ResourceExperiencePageComponent from '..';

const ResourceExperiencePage = () => {
    return (
        <Layout>
            <Grid>
                <ResourceExperiencePageComponent />
            </Grid>
        </Layout>
    );
};

ResourceExperiencePage.displayName = 'ResourceExperiencePage';

export default ResourceExperiencePage;

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const SnackBar = ({ ...props }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleClose}
      message={props.msg} // Guardado
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export const parseModusToModel = (modus) => ({
  id: modus?.id,
  name: modus?.name,
  description: modus?.description,
  image: `${process.env.REACT_APP_API_URL}${modus?.imageSrc}`, // TODO : Review this url
});

export const parseModusToObject = (data) => ({
  ...data,
});

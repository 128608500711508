import clsx from 'clsx';
import { Grid, Button } from '@material-ui/core';
import Layout from 'components/Layout';
import { useTranslation } from 'core/contexts/localization';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'core/contexts/auth';
import { useExperiences } from 'core/contexts/experience';
import { useSchedules } from 'core/contexts/schedule';
import { DatePicker } from '@material-ui/pickers';
import ExperienceSelect from '../ExperienceSelect';
import AddBookingForm from '../AddBoking/form';
import { ScheduleService } from 'core/api/services/schedule';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'core/routing/routes';
import { formatDate } from 'core/packages/format';

import { useStyles } from './styles';

const GlobalAddBooking = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const { experiences, getExperiencesUpdated } = useExperiences();

    const [experienceId, setExperienceId] = useState(-1);
    const getExperienceDetailId = () => {
        const exp = experiences.find((exp) => exp.id == experienceId);
        return exp.experienceDetailId;
    };

    const { getUserInfoSync } = useAuth();

    useEffect(() => {
        getUserInfoSync().then(async (usr) => {
            return await getExperiencesUpdated(usr.experiencer.id);
        });
    }, []);

    const renderColumn = (title, children) => {
        return (
            <div style={{ paddingRight: '2rem' }}>
                <h2>{title}</h2>
                {children}
            </div>
        );
    };

    const todayDate = new Date();
    const [selectedDate, setSelectedDate] = useState(null);

    const { schedules, getSchedulesUpdated } = useSchedules();
    const [selectedScheduleId, setSelectedScheduleId] = useState(-1);
    const [selectedSchedule, setSelectedSchedule] = useState(null);

    // for debugging purposes only
    // useEffect(() => {
    //   console.log(
    //     'selectedSchedule changed, and its info is: ',
    //     selectedSchedule
    //   );
    // }, [selectedSchedule]);

    useEffect(() => {
        // if date and experience was selected, fetch schedules
        if (selectedDate !== null && experienceId !== -1) {
            const experienceDetailId = getExperienceDetailId();
            const mod = 1;
            const date = new Date(selectedDate);
            const formattedDay = `${date.getFullYear()}-${
                date.getMonth() + 1 < 10 ? '0' : ''
            }${date.getMonth() + 1}-${
                date.getDate() < 10 ? '0' : ''
            }${date.getDate()}`;
            getSchedulesUpdated(
                experienceId,
                experienceDetailId,
                formattedDay,
                mod
            );
        }
    }, [experienceId, selectedDate]);

    useEffect(() => {
        setSelectedSchedule(null);
        setSelectedScheduleId(-1);
    }, [experienceId, selectedDate]);

    const renderSchedules = useMemo(() => {
        if (selectedDate === null) {
            return null;
        }

        return (
            <div style={{ display: 'grid', justifyContent: 'center' }}>
                {schedules.length > 0 ? (
                    schedules.map((schedule) => {
                        const time =
                            schedule.startTime.substring(
                                0,
                                schedule.startTime.lastIndexOf(':')
                            ) +
                            ' - ' +
                            schedule.endTime.substring(
                                0,
                                schedule.endTime.lastIndexOf(':')
                            );
                        let body = `${time}`;
                        let classname = clsx(
                            classes.triperButton,
                            classes.bgGreen300
                        );
                        let style = {
                            justifyContent: 'center',
                            width: '15rem',
                        };
                        if (
                            selectedScheduleId !== -1 &&
                            schedule.id == selectedScheduleId
                        ) {
                            body = (
                                <div style={{ height: '3rem' }}>
                                    <p
                                        style={{
                                            fontSize: '0.75rem',
                                            margin: '0px',
                                            height: '0.75rem',
                                            marginTop: '-0.3rem',
                                        }}>{`${time}`}</p>
                                    <p
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: '500',
                                            margin: '0px',
                                            height: '1rem',
                                            paddingTop: '0.25rem',
                                        }}>
                                        {translate(
                                            'slot.selected'
                                        ).toUpperCase()}
                                    </p>
                                </div>
                            );
                            style = { ...style, backgroundColor: '#9bc2d1' };
                            classname = classes.triperButton;
                        }

                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                style={{ padding: '0.1rem' }}
                                key={schedule.id}>
                                <button
                                    type="button"
                                    onClick={async () => {
                                        setSelectedScheduleId(schedule.id);
                                        // fetch schedule information
                                        const experienceDetailId =
                                            getExperienceDetailId();
                                        const scheduleData =
                                            await ScheduleService.getByScheduleId(
                                                experienceId,
                                                experienceDetailId,
                                                schedule.id,
                                                1,
                                                new Date(selectedDate)
                                            );
                                        setSelectedSchedule(scheduleData);
                                    }}
                                    style={style}
                                    className={classname}>
                                    {body}
                                </button>
                            </Grid>
                        );
                    })
                ) : (
                    <div style={{ display: 'grid' }}>
                        <h3>
                            {translate('global_bookings.no_schedules_found')}
                        </h3>
                        <Button
                            type="button"
                            className={clsx(
                                classes.triperButton,
                                classes.bgBlueroo
                            )}
                            onClick={(event) => {
                                event.preventDefault();
                                const experienceDetailId =
                                    getExperienceDetailId();
                                history.push({
                                    pathname: `${
                                        ROUTES.SCHEDULING
                                    }/${experienceId}/${experienceDetailId}?date=${getParsedDate(
                                        selectedDate
                                    )}`,
                                    state: {
                                        date: selectedDate,
                                    },
                                });
                            }}>
                            {translate('global_bookings.add_schedule')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }, [selectedDate, schedules, selectedSchedule, selectedScheduleId]);

    const getParsedDate = (date) => {
        const year = `${
            date.getUTCFullYear < 10 ? '0' : ''
        }${date.getUTCFullYear()}`;
        const month = `${date.getUTCMonth() + 1 < 10 ? '0' : ''}${
            date.getUTCMonth() + 1
        }`;
        const day = `${date.getUTCDate() < 10 ? '0' : ''}${date.getUTCDate()}`;
        return `${year}-${month}-${day}`;
    };

    const renderBookingForm = () => {
        return (
            <AddBookingForm
                onDismiss={() => {
                    history.push(ROUTES.BOOKINGS.GLOBAL);
                }}
                onSave={() => {}}
                expId={experienceId}
                subExpId={
                    selectedSchedule !== null ? getExperienceDetailId() : -1
                }
                displayArrivalTime={selectedSchedule.time !== null}
                slots={selectedSchedule !== null ? selectedSchedule.slots : []}
                start={
                    selectedSchedule !== null ? selectedSchedule.start : null
                }
                end={selectedSchedule !== null ? selectedSchedule.end : null}
                startTime={
                    selectedSchedule !== null
                        ? getTime(selectedSchedule.start)
                        : null
                }
                endTime={
                    selectedSchedule !== null
                        ? getTime(selectedSchedule.end)
                        : null
                }
                from={
                    selectedSchedule !== null
                        ? `${getParsedDate(selectedDate)}T00:00:00.000Z`
                        : null
                }
            />
        );
    };

    const getTime = (dateIso) => {
        // date in ISO format, so 2022-07-13T06:30:00.000Z extract time as 06:30:00
        const time = dateIso.substring(
            dateIso.lastIndexOf('T') + 1,
            dateIso.lastIndexOf('.')
        );
        return time;
    };

    /*
     * date is a string like "2022-07-15"
     * dateIso is a string like "2022-07-13T06:30:00Z"
     * returns the dateIso with the date string replaced so in this case it will return "2022-07-15T06:30:00Z"
     */
    const getDate = (date, dateIso) => {
        const dateIsoTime = dateIso.substring(date.lastIndexOf('T') + 1);
        return date + 'T' + dateIsoTime;
    };

    return (
        <Layout contentClassName="content">
            <Grid container style={{ padding: '1rem 2rem' }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ marginBottom: '1rem' }}>
                    {renderColumn(
                        translate('global_bookings.pick_an_experience'),
                        <ExperienceSelect
                            experiences={experiences}
                            onChange={setExperienceId}
                            value={experienceId === -1 ? null : experienceId}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ marginBottom: '1rem' }}>
                    {renderColumn(
                        translate('global_bookings.pick_a_date'),
                        <DatePicker
                            style={{
                                display: 'grid',
                                justifyContent: 'center',
                            }}
                            autoOk
                            variant="static"
                            value={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            disableToolbar
                            minDate={todayDate}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ marginBottom: '1rem' }}>
                    {selectedDate !== null &&
                        experienceId !== -1 &&
                        renderColumn(
                            `${translate(
                                'global_bookings.pick_schedule_for_date'
                            )}`,
                            renderSchedules
                        )}
                    {(selectedDate === null || experienceId === -1) &&
                        renderColumn(
                            translate(
                                'global_bookings.select_experience_and_date_to_view_schedules'
                            ),
                            <React.Fragment></React.Fragment>
                        )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ marginBottom: '1rem' }}>
                    {renderColumn(
                        translate('global_bookings.booking_information'),
                        renderBookingForm()
                    )}
                </Grid>
            </Grid>
        </Layout>
    );
};

export default GlobalAddBooking;

import Select from 'core/libs/core-ui/components/Input/Select';

const CheckoutItemSelect = ({ id, value, values, onChange = () => {},  ...props }) => {
    return (
        <Select
            id={id}
            label={'Agregar actividad'}
            values={values}
            onChange={onChange}
            menuItemProps={{
                style: {
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                },
            }}
            value={value}
        />
    );
};

export default CheckoutItemSelect;

module.exports = (function () {

    function timeDiffCalc(d, s) {
        let diffInMilliSeconds = Math.abs(d.getTime() - s.getTime()) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        // console.log('calculated days', days);

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        // console.log('calculated hours', hours);

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        // console.log('minutes', minutes);

        const seconds = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= seconds * 60;
        // console.log('seconds', seconds);

        let difference = '';
        if (days > 0) {
            difference += days === 1 ? `${days} day, ` : `${days} days, `;
        }

        difference += hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

        difference += minutes === 0 || minutes === 1 ? `${minutes} minute, ` : `${minutes} minutes, `;

        difference += seconds === 0 || seconds === 1 ? `${seconds} second, ` : `${seconds} seconds, `;

        difference += diffInMilliSeconds === 0 || diffInMilliSeconds === 1 ? `${diffInMilliSeconds} millisecond` : `${diffInMilliSeconds} milliseconds`;

        const result = {
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            milliseconds: diffInMilliSeconds,
            toString: function () {
                return difference;
            }
        };
        return result;
    }
    function getDuration(from, to, options = {}) {
        let duration = to.valueOf() - from.valueOf(); // The unit is millisecond		
        if (options.return) {
            switch (options.return) {
                case "hours":
                    duration = parseInt(duration / (60 * 60 * 1000)); // Turn the duration into hour format	
                    break;
                case "minutes":
                    duration = parseInt(duration / (60 * 1000)); // Turn the duration into hour format	
                    break;
                case "seconds":
                    duration = parseInt(duration / 1000); // Turn the duration into hour format	
                    break;
                default:
                    break;
            }
        }
        return duration;
    }
    function addMinutes(date, minutes, options = {}) {
        return new Date(date.getTime() + minutes * 60000);
    }
    function substractMinutes(date, minutes, options = {}) {
        return new Date(date.getTime() - minutes * 60000);
    }
    function addHours(date, hours, options = {}) {
        return new Date(date.getTime() + hours * 60 * 60000);
    }
    function substractHours(date, hours, options = {}) {
        return new Date(date.getTime() - hours * 60 * 60000);
    }
    function dateTimeFormatted(date, language, options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC',
    }, translate = (e, text) => text || e) {

        if (!date) { return translate('libs.date.noDate', 'No Date'); }

        if (!language) {
            language = 'es-ES';
        }

        const dateFormatted = new Date(date).toLocaleDateString(language, options);
        return dateFormatted;
    }
    function timeDurationFormatter(minutes, translate = (e, text) => text || e ) {
        const days = parseInt(minutes / (60 * 24));
        const hours = parseInt((minutes % (60 * 24)) / 60);
        const minutesRes = parseInt(minutes % 60);

        let result = '';

        if (days > 0) {
            result += `${days}  ${days === 1 ? translate('libs.time.day','Day') : translate('libs.time.days','Days')} `;
        }

        if (hours > 0) {
            result += `${hours} ${hours === 1 ? translate('libs.time.hour','Hour') : translate('libs.time.hours','Hours')} `;
        }

        if (minutesRes > 0) {
            result += `${minutesRes}  ${minutesRes === 1 ? translate('libs.time.minute','Minutes') : translate('libs.time.minutes','Minutes')}`;
        }
        return result;
    }
    function isSameDate(dayL, dayR) {
        const dayLCopy = new Date(dayL);
        const dayRCopy = new Date(dayR);
        dayL.setHours(0, 0, 0, 0);
        dayR.setHours(0, 0, 0, 0);
        return dayL === dayR;
    }
    function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() es base 0; enero = 0
        const day = date.getDate().toString().padStart(2, '0'); // getDate() es base 1

        return `${year}-${month}-${day}`;
    }
    
    // Function to remove milliseconds from a date
    function removeMilliseconds(date) {
        return new Date(Math.floor(date / 1000) * 1000); // Rounds down to the nearest second
    }
    
    // Function to remove milliseconds and seconds from a date
    function removeMillisecondsAndSeconds(date) {
        return new Date(Math.floor(date / 60000) * 60000); // Rounds down to the nearest minute
    }
    function compareDates({d1,d2,options}) {
        // dates
        let date1 = new Date(d1);
        let date2 = new Date(d2);
        
        if(options.removeMilliseconds) { 
            // Remove milliseconds
            date1 = removeMilliseconds(date1);
            date2 = removeMilliseconds(date2);
        }
        if(options.removeMillisecondsAndSeconds) {
            // Remove milliseconds and seconds
            date1 = removeMillisecondsAndSeconds(date1);
            date2 = removeMillisecondsAndSeconds(date2);
        }
        
        return date1.getTime() === date2.getTime();
    }


    return {
        /**
        * @name timeDiffCalc - This function receive a two dates and calculates the time difference
        * @param {Date} d - Day in future
        * @param {Date} s - Day in past
        * @return {object} - Object containing hours, minutes, seconds, miliseconds, and toString()
        */
        timeDiffCalc,
        /**
        * getDuration - This function receive a two dates and calculates the duration
        * @param {Date} from - Day when start
        * @param {Date} to - Day when finish
        * @param {object} options - options i.e. {return: "hours/minutes/seconds/miliseconds"}
        * @return {int} - result of duration, by default in miliseconds
        */
        getDuration,
        /**
        * addMinutes - This function receive a Date and Minutes and returns a new Date with the added minutes
        * @param {Date} date - Day when start
        * @param {int} minutes - Minutes to add
        * @param {object} options - options
        * @return {Date} - New date with added minutes
        */
        addMinutes,
        /**
        * substractMinutes - This function receive a Date and Minuts and returns a new Date with the substracted minutes
        * @param {Date} date - Day when start
        * @param {int} minutes - Minutes to substract
        * @param {object} options - options
        * @return {Date} - New date with substracted minutes
        */
        substractMinutes,
        /**
        * addHours - This function receive a Date and Hours and returns a new Date with the added hours
        * @param {Date} date - Day when start
        * @param {int} hours - Hours to add
        * @param {object} options - options
        * @return {Date} - New date with added hours
        */
        addHours,
        /**
        * substractHours - This function receive a Date and Hours and returns a new Date with the substracted minutes
        * @param {Date} date - Day when start
        * @param {int} hours - Hours to add
        * @param {object} options - options
        * @return {Date} - New date with substracted hours
        */
        substractHours,
        /**
        * @name dateTimeFormatted - returns a formatted date string
        * @param {Date} date
        * @param {String} language - Default es-ES
        * @param {Object} options
        * @param {Function} translate
        * @return {String} - 
        */
        dateTimeFormatted,
        /**
        * @name timeDurationFormatter - returns a formatted time string
        * @param {Number} minutes
        * @param {Function} translate
        * @return {String} - 
        */
        timeDurationFormatter,
        /**
        * @name isSameDate - Gets two dates and return if its the same ignoring the time, just dates
        * @param {Date} dateL
        * @param {Date} dateR
        * @return {Boolean} - 
        */
        isSameDate,
        /**
        * @name formatDateToYYYYMMDD
        * @param {String} dateString
        * @return {String} - 
        */
        formatDateToYYYYMMDD,
                /**
        * @name removeMilliseconds
        * @param {Date} date
        * @return {Date} - 
        */
        removeMilliseconds,
        /**
        * @name removeMillisecondsAndSeconds
        * @param {Date} date
        * @return {Date} - 
        */
        removeMillisecondsAndSeconds,
        /**
        * @name compareDates
        * @param {Date} d1
        * @param {Date} d2
        * @param {any} options
        * @return {Boolean} - 
        */
        compareDates,
    };
})();

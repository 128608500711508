import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: '60px 40px',
        [theme.breakpoints.down('xs')]: {
            padding: '60px 10px',
        },
    },
    availabilityInfo: {
        display: 'flex',
        gap: '10px',
        position: 'absolute',
        alignItems: 'center !important',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '20px',
            position: 'inherit',
            display: 'block',
        },
        '& div': {
            display: 'flex',
            gap: '10px',
        },
    },

    chip: {
        // width: '30px',
        // height: '25px',
        color: 'white',
    },
}));

import { isEmpty } from 'core/packages/utils';
import { fetch, Method } from 'core/api/fetch';
import { parseTripToModel } from 'core/api/parser/trip';
import {
    parseExperienceToModel,
    parseExperienceElementListToModel,
    parseQrDataToModel,
    parseExperienceScheduleToModel,
} from '../parser/experience';

const experienceRoutes = {
    getAll: '/api/v1.1.0/experience',
    updateStatus: '/api/v1.1.0/experience',
    getByHostel: (hostelCode, priceOrder, modusesFilter, tourOperatorsId) =>
        `/api/v1.1.0/experience?code=${hostelCode}&getExpsDetails=true`,
    getBundleByHostel: (hostelCode) =>
        `/api/v1.1.0/experience/bundle?code=${hostelCode}&getExpsDetails=true`,
    getExperienceSchedule: ({id, range}) => `/api/v1.1.0/experiencer/experience/${id}/ExperienceSchedule?start=${range?.start?.toISOString()}&end=${range.end?.toISOString()}`,
    getExperienceScheduleId: ({id, schId}) => `/api/v1.1.0/experiencer/experience/${id}/ExperienceSchedule/${schId}`,
    addExperienceSchedule: ({experienceDetailId}) => `/api/v1.1.0/experiencer/experience/${experienceDetailId}/ExperienceSchedule`,
    updateExperienceSchedule: ({experienceDetailId, experienceScheduleId, all}) => `/api/v1.1.0/experiencer/experience/${experienceDetailId}/ExperienceSchedule/${experienceScheduleId}?all=${all}`,
    deleteExperienceSchedule: ({experienceDetailId, experienceScheduleId, all}) => `/api/v1.1.0/experiencer/experience/${experienceDetailId}/ExperienceSchedule/${experienceScheduleId}?all=${all}`,
};

const buildFiltersQueryParams = ({
    locationData = null,
    radius = null,
    moduses = null,
}) => {
    let queryParams = '';
    let addedFirstQueryParam = false;
    if (
        locationData &&
        radius &&
        locationData.lng &&
        locationData.lat &&
        locationData.name
    ) {
        queryParams += `lng=${locationData.lng}&lat=${locationData.lat}&radius=${radius}`;
        addedFirstQueryParam = true;
    }
    if (moduses && moduses.length > 0) {
        queryParams += `${
            addedFirstQueryParam ? '&' : ''
        }moduses=[${moduses.join(',')}]`;
        addedFirstQueryParam = true;
    }
    return queryParams;
};

export const ExperienceService = {
    get: async (params) => {
        try {
            let query = '';
            if (params) {
                query += '?';
                if (params.expId) {
                    query += `expId=${params.expId}`;
                }
                if (params.date) {
                }
                if (params.dis) {
                }
                if (params.qty) {
                }
            }
            const { data } = await fetch(experienceRoutes.getAll + query, {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // if (isEmpty(data)) {
            //   return [];
            // } else if (!params.date) {
            //   data.map(parseExperienceToModel);
            // } else if (params.date) {
            //   data.map(parseTripExperienceToModel);
            // }
            return isEmpty(data) ? [] : data.map(parseExperienceToModel);
        } catch (err) {
            throw new Error(err);
        }
    },
    getByHostel: async (
        hostelCode,
        priceOrder,
        modusesFilter,
        tourOperatorsId
    ) => {
        try {
            const filtersQueryParams = buildFiltersQueryParams({
                moduses: modusesFilter,
            });
            const { data } = await fetch(
                experienceRoutes.getByHostel(
                    hostelCode,
                    priceOrder,
                    filtersQueryParams,
                    tourOperatorsId
                ),
                {
                    method: Method.GET,
                    authenticated: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(!data);
            return !data || isEmpty(data) ? [] : parseQrDataToModel(data);
        } catch (err) {
            throw new Error(err);
        }
    },
    getBundleByHostel: async (
        hostelCode,
        priceOrder,
        modusesFilter,
        tourOperatorsId
    ) => {
        try {
            // const filtersQueryParams = buildFiltersQueryParams({ moduses: modusesFilter });
            const { data } = await fetch(
                experienceRoutes.getBundleByHostel(hostelCode),
                {
                    method: Method.GET,
                    authenticated: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return !data
                ? { bundle: [], total: 0 }
                : { total: data.total, bundle: data.bundle };
        } catch (err) {
            throw new Error(err);
        }
    },
    updateStatus: async (expId, newData) => {
        try {
            const { data } = await fetch(experienceRoutes.updateStatus, {
                method: Method.PATCH,
                authenticated: true,
                data: newData,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return isEmpty(data) ? {} : data;
        } catch (err) {
            throw new Error(err);
        }
    },
    getUpdated: async (expId) => {
        try {
            if (expId.length <= 0)
                throw new Error(
                    'User experiencer id is undefined, could not load experiences!'
                );
            const { data } = await fetch(
                `${experienceRoutes.getAll}?expId=${expId}&draft=true`,
                {
                    authenticated: true,
                    method: Method.GET,
                }
            );
            return isEmpty(data) ? [] : data.map(parseExperienceToModel);
        } catch (err) {
            console.error('[GET EXPERIENCES UPDATED]', err);
        }
    },

    getAllExperiences: async (expId) => {
        try {
            if (expId.length <= 0)
                throw new Error(
                    'User experiencer id is undefined, could not load experiences!'
                );
            const { data } = await fetch(
                `${experienceRoutes.getAll}?expId=${expId}&draft=true`,
                {
                    authenticated: true,
                    method: Method.GET,
                }
            );
            return isEmpty(data) ? [] : data;
        } catch (err) {
            console.error('[GET EXPERIENCES UPDATED]', err);
        }
    },
    getDateExperiences: async (dateParam, dispParam, qtyParam) => {
        try {
            if (!dateParam) {
                throw new Error('Date was not provided!');
            }
            let url = `${experienceRoutes.getAll}?date=${dateParam}`;
            if (dispParam) {
                url += `&disp=${dispParam}`;
            }
            if (qtyParam) {
                url += `&qty=${qtyParam}`;
            }
            let { data } = await fetch(url, {
                authenticated: true,
                method: Method.GET,
            });
            return isEmpty(data)
                ? []
                : data.map(parseExperienceElementListToModel);
        } catch (err) {
            console.error('[GET DATE EXPERIENCES]', err);
        }
    },

    getExperienceSchedule: async (options) => {
        try {
            const { data } = await fetch(experienceRoutes.getExperienceSchedule(options), {
                method: Method.GET,
                authenticated: true,
            });
              return !data || !data.length ? [] : data.map(parseExperienceScheduleToModel);
        } catch (err) {
            throw new Error(err);
        }
    },

    // getExperienceScheduleId: async (options) => {
    //     try {
    //         const { data } = await fetch(experienceRoutes.getExperienceScheduleId(options), {
    //             method: Method.GET,
    //             authenticated: true,
    //         });
    //           return !data || !data.length ? [] : data.map(parseExperienceScheduleToModel);
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    addExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(experienceRoutes.addExperienceSchedule(info), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(experienceRoutes.updateExperienceSchedule(info), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteExperienceSchedule: async (info) => {
        try {
            const { data } = await fetch(experienceRoutes.deleteExperienceSchedule(info), {
                method: Method.DELETE,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};

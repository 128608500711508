import React from 'react';
import BasicTable from 'core/libs/core-ui/components/Table';
import { Grid } from '@material-ui/core';

const ResourceTable = ({ resources, openModal, ...props }) => {

    const columns = [
        {
            val: 'name',
            label: 'Nombre',
            column: 'name',
        },
        { val: 'mobilePhone', label: 'Phone', column: 'mobilePhone' },
        { val: 'email', label: 'Email', column: 'email' },
        {
            val: 'createdOnFormatted',
            label: 'Creado',
            column: 'createdOnFormatted',
        },
        {
            val: 'categoryName',
            label: 'Categoria',
            column: 'categoryName',
        },
    ];

    const onClick = (event, id) => {
        openModal(event, id);
    };
    return (
        <Grid>
            <BasicTable
                columns={columns}
                values={resources}
                onClick={(event, id) => onClick(event, id)}
            />
            <br />
        </Grid>
    );
};
export default ResourceTable;

import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../../styles';

const SearchBox = ({ onChange, value, style, label }) => {
    const classes = useStyles();
    return (
        <TextField
            id="outlined-basic"
            label={label}
            variant="outlined"
            style={{
                width: '20rem',
                ...style,
            }}
            className={clsx(classes.globalBookingsSearchBox)}
            onChange={(event) => {
                onChange(event);
            }}
            value={value}
        />
    );
};

export default SearchBox;
